import { MouseEvent } from 'react';
import toast from 'react-hot-toast';

export const BASE_URL = 'https://dev.carret.in/api/v1';

export const optimizeCloudinaryImage = ({ src, quality = 'auto' }: any) => {
  if (
    (src?.includes('https') || src?.includes('http')) &&
    src?.includes('cloudinary')
  ) {
    if (!src?.includes('https')) {
      // eslint-disable-next-line no-param-reassign
      src = src?.replace('http', 'https');
    }
    const optimizeURL: any = src.split('upload/');
    const endingUrl: any = optimizeURL[1]?.split('/').slice(1).join('/') || '';
    if (!endingUrl) {
      return src;
    }

    return [
      optimizeURL[0],
      `upload/q_${quality},f_auto/`,
      endingUrl.replace('q_auto/', ''),
    ].join('');
  }
  return src;
};

export const deleteCookie = (name: any) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
};

export const isEmptyObject = (obj: any) => {
  if (obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }
  return false;
};

export function getCookie(cname: any) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

// for seesion storage only
export const setToken = (tokenName: string, token: string) => {
  if (typeof window !== 'undefined') {
    sessionStorage?.setItem(tokenName, token);
  }
};

// for seesion storage only
export const removeToken = (tokenName: string) => {
  if (typeof window !== 'undefined') {
    sessionStorage?.removeItem(tokenName);
  }
};

export const getAuthToken = () => {
  if (typeof window !== 'undefined') {
    const token = sessionStorage?.getItem('fa_xrti494eeaiion');
    return token || ''; // Return an empty string if token is null or undefined
  }
  return null;
};

export const roundNumber = (originalNumber: number, decimal = 6) => {
  const multiplier = Math.pow(10, decimal);
  const roundedNumber = Math.floor(originalNumber * multiplier) / multiplier;

  return roundedNumber;
};

export const formatNumberWithCommas = (amount: number) => {
  return Intl.NumberFormat('en-US', {}).format(amount);
};

export const stopPropagationHandlerDiv = (
  event: MouseEvent<HTMLDivElement>,
) => {
  event.stopPropagation();
};

export const isValidMobileNumber = (number: string) => {
  const mobileNumberPattern =
    /^\+?\d{10}$|^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

  return mobileNumberPattern.test(number);
};

export const errorReaderToast = (error: any) => {
  console.log(error.message);
  const data = JSON.parse(error.message);
  console.log(data);

  if (typeof data !== 'object' || data === null) {
    console.error('Provided data is not a valid object');
    toast.error(error.message);
    return;
  }

  Object.entries(data).forEach(([key, messages]) => {
    if (Array.isArray(messages)) {
      messages.forEach((message) => {
        toast.error(`${key}: ${message}`);
      });
    } else if (typeof messages === 'string') {
      toast.error(`${key}: ${messages}`);
    } else {
      console.error(
        `Expected an array of messages for key "${key}", but got ${typeof messages}`,
      );
    }
  });
};
