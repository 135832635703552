import React from 'react';
import ReactImage from '../ReactImage/ReactImage';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { saveSelectedFiat } from '../../redux/reducer/CommonReducer';

const CurrencyList = ({ fiatList = [] }: any) => {
  const { selectedFiat } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();
  return (
    <div className="px-3">
      <h2 className="text-lg font-semibold">Select Currency</h2>
      <div className="pt-2">
        {fiatList.map((item: any) => (
          <div
            className="flex gap-3"
            onClick={() => dispatch(saveSelectedFiat(item))}
            key={item?.symbol}
          >
            <input
              type="radio"
              id={item?.symbol}
              name="fiat"
              value={item?.symbol}
              checked={item.id === selectedFiat?.id}
            />
            <label htmlFor={item?.symbol} className="flex gap-2 items-center">
              <span>
                <ReactImage
                  src={item?.img_url}
                  height={20}
                  width={20}
                  alt="fiat icon"
                />
              </span>
              {item?.symbol} ({item?.name})
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurrencyList;
