import React, { useEffect, useState } from 'react';
import styles from './otp.module.scss';
import OTPInput from 'react-otp-input';

interface FluidOtpProps {
  otp: string;
  setOtp: (otp: string) => void;
}

export const FluidOtp: React.FC<FluidOtpProps> = ({ otp, setOtp }) => {
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  // Handle resend OTP button click
  const handleResendClick = () => {
    // Code to resend OTP goes here
    // For example, you can reset the timer and resend OTP
    setSeconds(60);
  };

  return (
    <div className={styles.otpContainer}>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span> </span>}
        inputType="number"
        renderInput={(
          props: React.JSX.IntrinsicAttributes &
            React.ClassAttributes<HTMLInputElement> &
            React.InputHTMLAttributes<HTMLInputElement>,
        ) => <input className={styles.otpInput} type="number" {...props} />}
      />

      {seconds > 0 ? (
        <p className={styles.otpTimerText}>
          Resend OTP in 0:{seconds < 10 ? `0${seconds}` : seconds}
        </p>
      ) : (
        <button onClick={handleResendClick} className={styles.resendBtn}>
          Resend OTP
        </button>
      )}
    </div>
  );
};
