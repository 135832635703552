import React, { FormEvent, useState } from 'react';
import ReactImage from '../../components/ReactImage/ReactImage';
import { assetObject } from '../../utils/assetFileName';
import { FluidInput } from '../../components/FluidInput/FluidInput';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  saveCurPriceTrigger,
  saveOrderTime,
  savePageType,
  saveWalletAddress,
} from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';
import moment from 'moment';
import { WalletDropDown } from '../../components/WalletDropDown/WalletDropDown';
import { WalletAddressListInterface } from '../../../typing/interface';
import toast from 'react-hot-toast';

export const WalletId = () => {
  const { selectedAsset } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();
  const [walletDetails, setWalletDetails] =
    useState<WalletAddressListInterface | null>();

  const submitWalletIdHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!walletDetails) {
      toast.error('Select wallet address');
      return;
    }

    dispatch(saveWalletAddress(walletDetails));
    dispatch(savePageType(PageType.ORDER_DETAILS));

    const now = moment();
    const endTime = now.add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss');

    dispatch(saveOrderTime({ now, endTime }));
    dispatch(saveCurPriceTrigger(false));
  };
  return (
    <>
      <FluidHeader
        title="Wallet Id"
        onClickHandler={() => dispatch(savePageType(PageType.HOME_PAGE))}
      />
      <section className="p-4">
        <form onSubmit={submitWalletIdHandler}>
          <div className="flex justify-center">
            <ReactImage src={assetObject.walletSvg} alt="wallet-img" />
          </div>
          <div className="text-sm font-semibold pt-3">
            <span>Enter your wallet address to receive</span>{' '}
            <span className="color-primary">{selectedAsset?.symbol}</span> on{' '}
            <span className="color-primary">
              {selectedAsset?.network} Network
            </span>
            .
          </div>
          <div className="pt-4">
            {/* <FluidInput inputName="wallet_id" required /> */}
            <WalletDropDown setWalletDetails={setWalletDetails} />
          </div>
          <div className="flex gap-2 text-xs pt-2 pl-2">
            <input type="checkbox" name="confirm" id="confirm" required />{' '}
            <label htmlFor="confirm">I confirm that I own the wallet</label>
          </div>
          <div className="border rounded-xl border-orange-300 text-xs font-semibold p-1 mt-4 flex gap-2 items-center">
            <span>
              <ReactImage
                src={assetObject.iSvg}
                alt="isvg"
                height={30}
                width={30}
              />
            </span>
            <div className="text-orange-300">
              Please make sure that the wallet address enetered is of{' '}
              <span className="color-primary">{selectedAsset?.symbol}</span> on{' '}
              <span className="color-primary">
                {selectedAsset?.network} Network
              </span>
              .
            </div>
          </div>
          <div className="flex items-start gap-2 text-xs pt-2 pl-2 mt-2">
            <input type="checkbox" name="check" id="check" required />{' '}
            <label htmlFor="check">
              I hereby acknowledge that any error in the Wallet address could
              result in the irreversible loss of funds and that Fluid will not
              be held accountable for such an occurrence.
            </label>
          </div>
          <div className="pt-4">
            <FluidButton
              variant="contained"
              color="primary"
              buttonType="submit"
            >
              Continue
            </FluidButton>
          </div>
        </form>
      </section>
    </>
  );
};
