import React, { MouseEvent, ReactNode } from 'react';
import styles from './FluidBtn.module.scss';
import FluidLoader from '../loader/FluidLoader';

interface FluidButtonProps {
  children: ReactNode;
  onClickHandler?: (event: MouseEvent<HTMLButtonElement>) => void;
  variant?: 'contained' | 'outlined';
  color?: 'error' | 'primary';
  buttonType?: 'submit' | 'reset' | 'button';
  loading?: boolean;
  disabled?: boolean;
}

export const FluidButton: React.FC<FluidButtonProps> = ({
  children,
  onClickHandler,
  variant = 'contained',
  color = 'primary',
  buttonType = 'button',
  loading = false,
  disabled = false,
}) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClickHandler) {
      onClickHandler(event);
    }
  };

  const buttonTypeHandler = (variant: string) => {
    switch (variant) {
      case 'contained':
        return styles.contained;
      case 'outlined':
        return styles.outlined;

      default:
        return styles.contained;
    }
  };

  const buttonColorHandler = (color: string) => {
    switch (color) {
      case 'primary':
        return styles.primaryBtn;
      case 'error':
        return styles.errorBtn;

      default:
        return styles.primaryBtn;
    }
  };

  return (
    <div className={buttonColorHandler(color)}>
      <button
        onClick={handleClick}
        className={`${styles.fluidBtn} ${
          loading && styles.loading
        } ${buttonTypeHandler(variant)} ${
          disabled ? 'opacity-40 cursor-default' : ''
        }`}
        type={buttonType}
        disabled={disabled}
      >
        {loading ? <FluidLoader width="1.5rem" /> : <>{children}</>}
      </button>
    </div>
  );
};
