import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { actionAssetPriceUpdate } from '../redux/action/CommonAction';
import moment from 'moment';
import { createTxnOrderApi } from '../utils/api';
import toast from 'react-hot-toast';
import { errorReaderToast, roundNumber } from '../utils';
import {
  resetCommonState,
  saveCurPriceTrigger,
  saveOrderTime,
  saveOrderTimeStatus,
  savePageType,
  saveUserPlacedOrderDetails,
} from '../redux/reducer/CommonReducer';
import { PageType } from '../utils/constants';

const useOrderHook = () => {
  const {
    curPriceTrigger,
    selectedAsset,
    selectedFiat,
    orderEndTime,
    userPlacedOrder,
    selectedBank,
    selectedUpi,
    curAssetPrice,
    userWalletAddress,
    orderFees,
    orderTimeEnded,
  } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();

  const [timer, setTimer] = useState('00:00');

  // start order timer function
  const startTimer = useCallback(
    (endTime: string | null) => {
      if (!endTime) return;

      const end = moment(endTime, 'YYYY-MM-DD HH:mm:ss');
      const interval = setInterval(() => {
        const now = moment();
        const duration = moment.duration(end.diff(now));

        if (duration.asSeconds() <= 0) {
          clearInterval(interval);
          dispatch(savePageType(PageType.ORDER_DETAILS));
          dispatch(saveOrderTimeStatus(true));
          dispatch(saveOrderTime({ now: null, endTime: null }));
          if (orderTimeEnded) {
            dispatch(resetCommonState());
            dispatch(savePageType(PageType.HOME_PAGE));
            toast.error('Time Ended!');
          }
        } else {
          const minutes = Math.floor(duration.asMinutes());
          const seconds = Math.floor(duration.asSeconds() % 60);
          const formattedMinutes = String(minutes).padStart(2, '0');
          const formattedSeconds = String(seconds).padStart(2, '0');
          setTimer(`${formattedMinutes}:${formattedSeconds}`);
        }
      }, 1000);

      return () => clearInterval(interval);
    },
    [dispatch, orderTimeEnded],
  );

  // place final order
  const placeOrderTxn = async (urtId: string) => {
    if (
      !userPlacedOrder ||
      !selectedAsset ||
      !selectedFiat ||
      !curAssetPrice ||
      !orderFees ||
      !userWalletAddress
    ) {
      toast.error('Something went wrong!');
      return;
    }
    const paymentMethod = selectedBank ? 'bank' : 'upi';
    const networkFeeFiat = orderFees?.network_fee * Number(curAssetPrice);

    try {
      const txn = {
        amount: String(userPlacedOrder?.fiat_amt),
        actual_amount: String(userPlacedOrder?.fiat_amt),
        payment_method: paymentMethod,
        txn_type: 'deposit',
        remarks: '',
        recipient_wallet: userWalletAddress?.id,
        asset: selectedFiat?.id,
        bank_id: paymentMethod === 'bank' ? selectedBank?.id : null,
        upi_id: paymentMethod === 'upi' ? selectedUpi?.id : null,
        order_id: null,
        utr_no: urtId,
      };
      const order = {
        asset: selectedAsset?.id,
        side: 'buy',
        network: selectedAsset?.network,
        asked_qty: userPlacedOrder?.asset_amt,
        fiat_amt: userPlacedOrder?.fiat_amt,
        quote_ccy: selectedFiat?.symbol,
        asset_fiat_price: curAssetPrice,
        recipient_wallet: userWalletAddress?.id,
        network_fee: orderFees?.network_fee,
        network_fee_fiat: roundNumber(networkFeeFiat, 2),
        fee: orderFees?.carret_fee,
        order_type: 'limit',
        usdt_price: '1',
        tds: 0,
        tag: '',
      };

      const response = await createTxnOrderApi({ order, txn });
      toast.success('Order Placed!');
    } catch (error) {
      console.log(error);
      errorReaderToast(error);
    }
  };

  // current price update
  useEffect(() => {
    if (!curPriceTrigger && !selectedAsset?.symbol) return;

    const intervalId = setInterval(() => {
      if (curPriceTrigger && selectedAsset?.symbol) {
        dispatch(
          actionAssetPriceUpdate({
            payload: {
              asset: selectedAsset?.symbol,
              network: selectedAsset?.network,
            },
            side: 'buy',
          }),
        );
      }
    }, 20000); // 20sec
    return () => clearInterval(intervalId);
  }, [curPriceTrigger, selectedAsset, dispatch]);

  // price update when order time ended / start cycle again
  useEffect(() => {
    if (orderTimeEnded && selectedAsset?.symbol && userPlacedOrder) {
      const now = moment();
      const endTime = now.add(10, 'minutes').format('YYYY-MM-DD HH:mm:ss');
      dispatch(saveOrderTime({ now, endTime }));

      dispatch(
        actionAssetPriceUpdate({
          payload: {
            asset: selectedAsset?.symbol,
            network: selectedAsset?.network,
          },
          side: 'buy',
        }),
      );

      const updatedCryptoNum =
        (userPlacedOrder?.fiat_amt - userPlacedOrder?.total_fees) /
        Number(curAssetPrice);

      dispatch(
        saveUserPlacedOrderDetails({
          ...userPlacedOrder,
          asset_amt: roundNumber(updatedCryptoNum, 4),
        }),
      );
      // dispatch(saveOrderTimeStatus(false));
      startTimer(orderEndTime);
    }
  }, [orderTimeEnded]);

  useEffect(() => {
    if (orderEndTime) startTimer(orderEndTime);
  }, [orderEndTime]);

  return { timer, startTimer, placeOrderTxn };
};

export default useOrderHook;
