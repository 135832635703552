import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { wipeCookies } from '../utils/auth';
import { saveLogoutUser } from '../redux/reducer/AuthReducer';
import { postVerifyOpt } from '../utils/api';
import { setToken } from '../utils';
import { savePageType } from '../redux/reducer/CommonReducer';
import { PageType } from '../utils/constants';
import { actionUserDetails } from '../redux/action/AuthAction';
import toast from 'react-hot-toast';

const useAuthHook = () => {
  const dispatch = useAppDispatch();

  const [btnLoading, setBtnLoading] = useState(false);
  const { userInfo, isUserLogedIn } = useAppSelector((state) => state.auth);

  const logoutUser = () => {
    wipeCookies();
    dispatch(saveLogoutUser());
  };

  const verifyOtp = async (email: string, otp: string) => {
    try {
      setBtnLoading(true);
      const response = await postVerifyOpt({
        email: email,
        otp: otp,
      });
      setToken('fa_xrti494eeaiion', response.access_token);
      setToken('fr_fasfekrkbnc933gsjsdj', response.access_token);
      const { payload } = await dispatch(actionUserDetails());
      if (payload.results && payload?.results[0]) {
        if (payload?.results[0].kyc_verified) {
          dispatch(savePageType(PageType.HOME_PAGE));
        } else {
          dispatch(savePageType(PageType.KYC_PAGE));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBtnLoading(false);
    }
  };

  const userKycCheck = () => {
    if (!isUserLogedIn) return false;
    if (!userInfo?.annual_income || !userInfo.occupation) {
      toast((t) => <span>Please add additional Info to Proceed</span>);
      return true;
    } else if (
      userInfo?.annual_income &&
      userInfo.occupation &&
      userInfo?.phone_verification_status === 'in_progress'
    ) {
      toast(() => 'Your Mobile number is under verfication.');
      return true;
    } else if (userInfo?.kyc_status === 'rejected') {
      toast.error('Your KYC has been rejected');
      return true;
    } else if (userInfo?.kyc_status === 'manual_review') {
      toast(() => 'Your KYC is under review.');
      return true;
    } else if (userInfo?.kyc_status === 'failed') {
      toast(() => 'Your KYC verification failed please try again.');
      return true;
    } else if (userInfo?.is_bank_added) {
      toast(() => 'Please add your bank to proceed.');
      return true;
    } else {
      return false;
    }
  };

  return { logoutUser, verifyOtp, btnLoading, userKycCheck };
};

export default useAuthHook;
