import React from 'react';
import { MissingDepositForm } from './Chunks/MissingDepositForm';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { useAppDispatch } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';

const MissingDepositClaim = () => {
  const dispatch = useAppDispatch();
  return (
    <main className="p-4">
      <FluidHeader
        title="Missing Deposit Claim"
        onClickHandler={() => dispatch(savePageType(PageType.HOME_PAGE))}
      />
      <MissingDepositForm />
    </main>
  );
};

export default MissingDepositClaim;
