import React, { useEffect, useState } from 'react';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { OrderHistoryInterface } from '../../../typing/interface';
import moment from 'moment';
import { actionOrderHistory } from '../../redux/action/CommonAction';
import FluidModal from '../../components/FluidModal/FluidModal';
import ReactImage from '../../components/ReactImage/ReactImage';
import { assetObject } from '../../utils/assetFileName';
import { roundNumber } from '../../utils';
import { OrderDetails } from './OrderDetails';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';

const OrderHistoryLayout = () => {
  const { orderHistory } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();
  const [selectedOrder, setSelectedOrder] =
    useState<OrderHistoryInterface | null>(null);

  const [openModal, setOpenModal] = useState(false);

  const openDetailsHandler = (order: OrderHistoryInterface) => {
    setSelectedOrder(order);
    setOpenModal(true);
  };
  const onCloseDetails = () => {
    setSelectedOrder(null);
    setOpenModal(false);
  };

  useEffect(() => {
    dispatch(actionOrderHistory());
  }, []);

  return (
    <section className="p-4">
      <FluidHeader
        title="Order History"
        onClickHandler={() => dispatch(savePageType(PageType.HOME_PAGE))}
      />
      {orderHistory?.map((data) => (
        <OrderCard
          key={data.id}
          orderHistory={data}
          openDetailsHandler={openDetailsHandler}
        />
      ))}
      <FluidModal
        modalStatus={openModal}
        cancelIconEnable
        onModalClose={onCloseDetails}
      >
        <OrderDetails selectedOrder={selectedOrder} />
      </FluidModal>
    </section>
  );
};

export default OrderHistoryLayout;

interface OrderCardProp {
  orderHistory: OrderHistoryInterface;
  openDetailsHandler: (order: OrderHistoryInterface) => void;
}

const OrderCard: React.FC<OrderCardProp> = ({
  orderHistory,
  openDetailsHandler,
}) => {
  const statusColorSwitch = (status: string) => {
    switch (status) {
      case 'success':
        return '--primary-green';
      case 'failed':
        return '--error-red';
      case 'pending':
        return '--pending-yellow';
      default:
        break;
    }
  };
  const status = 'success';
  return (
    <div
      className="flex p-2 border-b-2 border-gray-200 items-center gap-2"
      onClick={() => openDetailsHandler(orderHistory)}
    >
      <div
        className={`bg-[${statusColorSwitch(status)}] p-4 rounded-full h-fit`}
      >
        <div className="bg-white p-[1px]"></div>
      </div>
      <div className="flex justify-between w-full">
        <div>
          <div className="flex gap-2 items-center">
            <p
              className={`font-semibold text-lg text-[var(${statusColorSwitch(status)})]`}
            >
              {orderHistory.side}
            </p>
            <p className="text-xs">{orderHistory.status}</p>
          </div>
          <p className="text-xs">
            {' '}
            {moment(orderHistory.created_at).format('DD MMMM YYYY, HH:mm')}
          </p>
        </div>
        <div>
          <p
            className={`font-semibold text-[var(${statusColorSwitch(status)})] text-right`}
          >
            USDT
          </p>
          <p className={`text-[var(${statusColorSwitch(status)})]`}>
            {orderHistory.filled_quantity_on_lp}
          </p>
        </div>
      </div>
    </div>
  );
};
