import { UpdateAssetPricePayloadInterface } from '../../../typing/interface';
import {
  getAssetList,
  getAssetPrice,
  getFiatList,
  getOrderHistoryApi,
  getTransectionHistory,
  getWalletList,
} from '../../utils/api';
// import { getAssetList, getFiatList } from '../../utils/api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const actionFiatList = createAsyncThunk('common/fiatList', async () => {
  const response = await getFiatList();
  return response;
});
interface ActionAssetListType {
  onChainActive?: boolean;
}

export const actionAssetList = createAsyncThunk(
  'common/assetList',
  async ({ onChainActive = false }: ActionAssetListType) => {
    const response = await getAssetList(onChainActive);
    return response;
  },
);

export const actionAssetPriceUpdate = createAsyncThunk(
  'common/getAssetPrice',
  async ({ payload, side }: UpdateAssetPricePayloadInterface) => {
    const response = await getAssetPrice({ payload, side });
    return response;
  },
);

export const actionOrderHistory = createAsyncThunk(
  'common/getOrderHistoryApi',
  async () => {
    const response = await getOrderHistoryApi();
    return response;
  },
);
export const actionTransectionHistory = createAsyncThunk(
  'common/getTransectionHistory',
  async () => {
    const response = await getTransectionHistory();
    return response;
  },
);

export const actionWalletAddressList = createAsyncThunk(
  'common/getWalletList',
  async (status: string) => {
    const response = await getWalletList(status);
    return response;
  },
);
