import React, { useState } from 'react';
import { postAddBank } from '../../../utils/api';
import { FluidInput } from '../../../components/FluidInput/FluidInput';
import { FluidButton } from '../../../components/FluidButton/FluidButton';
import FluidModal from '../../../components/FluidModal/FluidModal';
import { StatusModal } from '../../../components/StatusModal/StatusModal';

interface BankAddedState {
  status: 'success' | 'error';
  text: string;
}

export const AddBank = () => {
  const [accNoError, setAccNoError] = useState(false);
  const [isBankAdded, setIsBankAdded] = useState<BankAddedState>({
    status: 'success',
    text: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const addBankHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const acc_holder_name = (event.target as HTMLFormElement).acc_holder_name
      .value;
    const bank_name = (event.target as HTMLFormElement).bank_name.value;
    const acc_no = (event.target as HTMLFormElement).acc_no.value;
    const re_acc_no = (event.target as HTMLFormElement).re_acc_no.value;
    const ifsc_code = (event.target as HTMLFormElement).ifsc_code.value;

    if (acc_no !== re_acc_no) {
      setAccNoError(true);
      setLoading(false);
      return;
    } else {
      setAccNoError(false);
    }

    const payload = {
      acc_name: acc_holder_name,
      bank_name: bank_name,
      acc_no: acc_no,
      ifsc: ifsc_code,
    };

    try {
      const response = await postAddBank(payload);
      setIsBankAdded({
        status: 'success',
        text: 'You bank account has been verifed and added successfully',
      });
      setOpenModal(true);
    } catch (error: any) {
      const respnse: any = JSON.parse(error.message);

      setIsBankAdded({
        status: 'error',
        text: respnse?.error
          ? respnse?.error
          : 'Your bank account is not verified',
      });
      setOpenModal(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <p className="text-sm font-semibold pl-1 mt-4">Add Bank details</p>
      <form onSubmit={addBankHandler}>
        <div className="my-4">
          <FluidInput inputName="bank_name" label="Bank Name" required />
        </div>
        <div className="my-4">
          <FluidInput
            inputName="acc_holder_name"
            label="Account holder"
            required
          />
        </div>
        <div className="my-4">
          <FluidInput
            inputName="acc_no"
            label="Account number"
            type="password"
            required
          />
        </div>
        <div className="my-4">
          <FluidInput
            inputName="re_acc_no"
            label="Re-enter account number"
            type="number"
            required
            inputError={accNoError}
            helperText="Acc no dosen't match"
          />
        </div>
        <div className="my-4">
          <FluidInput inputName="ifsc_code" label="IFSC code" required />
        </div>
        <div className="mt-8">
          <FluidButton
            variant="contained"
            color="primary"
            buttonType="submit"
            loading={loading}
          >
            Add
          </FluidButton>
        </div>
      </form>
      <FluidModal
        modalStatus={openModal}
        cancelIconEnable
        onModalClose={() => setOpenModal(false)}
      >
        <StatusModal text={isBankAdded.text} statusType={isBankAdded.status} />
      </FluidModal>
    </div>
  );
};
