export const PageType = {
  HOME_PAGE: 'HOME_PAGE',
  LOGIN_PAGE: 'LOGIN_PAGE',
  KYC_PAGE: 'KYC_PAGE',
  WALLET_ID: 'WALLET_ID',
  ORDER_DETAILS: 'ORDER_DETAILS',
  SELECT_PAYMENT_MODE: 'SELECT_PAYMENT_MODE',
  ADD_UTR_BANK: 'ADD_UTR_BANK',
  ADD_UTR_UPI: 'ADD_UTR_UPI',
  ADD_PAYMENT_METHOD: 'ADD_PAYMENT_METHOD',
  MISSING_DEPOSIT_CLAIM: 'MISSING_DEPOSIT_CLAIM',
  SUPPORT: 'SUPPORT',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO',
  ADD_WALLET_ADDRESS: 'ADD_WALLET_ADDRESS',
  ADDRESS_LIST: 'ADDRESS_LIST',
  TRANSACTION_HISTORY: 'TRANSACTION_HISTORY',
  ORDER_HISTORY: 'ORDER_HISTORY',
};

export {};
