import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserDetails } from '../../utils/api';

export const actionUserDetails = createAsyncThunk(
  'auth/getUserDetails',
  async () => {
    const response = await getUserDetails();
    return response;
  },
);
