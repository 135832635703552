import { configureStore } from '@reduxjs/toolkit';
import {
  useDispatch,
  type TypedUseSelectorHook,
  useSelector,
} from 'react-redux';
import CommonReducer from './reducer/CommonReducer';
import AuthReducer from './reducer/AuthReducer';

export const store = configureStore({
  reducer: {
    common: CommonReducer,
    auth: AuthReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
