import React, { useEffect, useState } from 'react';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { AddressCard } from './AddressCard';
import { AddAddressModal } from './AddAddressModal';
import { getWalletList } from '../../utils/api';
import { addrListInterface } from '../../../typing/interface';
import { useAppDispatch } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';

const AddWalletAddress = () => {
  const [addrList, setAddrList] = useState<addrListInterface[]>([]);
  const [updateList, setUpdateList] = useState(false);
  const dispatch = useAppDispatch();
  const walletAddressList = async () => {
    try {
      const response = await getWalletList();

      setAddrList(response.results);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    walletAddressList();
  }, [updateList]);
  return (
    <div className="px-4">
      <FluidHeader
        title="Wallet Address"
        onClickHandler={() => dispatch(savePageType(PageType.HOME_PAGE))}
      />
      <AddAddressModal />
      <div className="flex flex-col gap-4">
        {addrList.map((item) => (
          <AddressCard
            key={item.id}
            data={item}
            setUpdateList={setUpdateList}
          />
        ))}
      </div>
    </div>
  );
};

export default AddWalletAddress;
