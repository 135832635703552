import { getAuthToken } from '..';
import { wipeCookies } from '../auth';

const isProduction: boolean = process.env.NODE_ENV === 'production';

const API_URL: string = isProduction
  ? 'https://carret.in/fluid/api/v1'
  : 'https://carret.in/fluid/api/v1';

const readJSONBody = async (res: Response): Promise<any | null> => {
  if (!res.headers.get('content-type')) return null;
  return res.headers.get('content-type')!.startsWith('application/json')
    ? await res.json()
    : null;
};

async function request(
  method: string,
  endpoint: string,
  payload: any | null = null,
  header: HeadersInit = {},
  apiUrl: string = API_URL,
): Promise<any> {
  const _authToken: string | null = getAuthToken();
  const requestHeaders: HeadersInit = new Headers(header);
  requestHeaders.set('Authorization', `Bearer ${_authToken}`);
  requestHeaders.set('Content-Type', 'application/json');

  const opts: RequestInit = {
    headers: requestHeaders,
    method,
  };

  if (payload) {
    // multipart data
    if (payload instanceof FormData) {
      opts.body = payload;
    } else {
      opts.body = JSON.stringify(payload);
    }
  }

  const URL: string = /^(http|https):\/\//i.test(endpoint)
    ? endpoint
    : `${apiUrl}/${endpoint}`;

  try {
    const res: Response = await fetch(URL, opts);

    const body: any | null = await readJSONBody(res);

    // Follow the happy path if 200 OK
    if (res.ok) return body;

    if (res.status === 403) {
      wipeCookies();
      // window.location.href = '/';
    }

    if (res.status >= 400) {
      throw new Error(JSON.stringify(body));
    }

    // Handle other errors without token refresh
    handleErrors(res);
    return body;
  } catch (err: any) {
    throw new Error(err.message);
  }
}

function handleErrors(res: Response): void {
  if (res.status === 403) {
    // window.location.href = '/login';
  }
}

export function get(
  endpoint: string,
  header: Record<string, string> = {},
  apiUrl: string = API_URL,
): Promise<any> {
  return request('GET', endpoint, null, header, apiUrl);
}

export function patch(
  endpoint: string,
  payload: any,
  header: Record<string, string> = {},
  apiUrl: string = API_URL,
): Promise<any> {
  return request('PATCH', endpoint, payload, header, apiUrl);
}

export function post(
  endpoint: string,
  payload: any,
  header: Record<string, string> = {},
  apiUrl: string = API_URL,
): Promise<any> {
  return request('POST', endpoint, payload, header, apiUrl);
}

export function put(
  endpoint: string,
  payload: any,
  header: Record<string, string> = {},
  apiUrl: string = API_URL,
): Promise<any> {
  return request('PUT', endpoint, payload, header, apiUrl);
}

export function del(
  endpoint: string,
  header: Record<string, string> = {},
  body: any | null = null,
  apiUrl: string = API_URL,
): Promise<any> {
  return request('DELETE', endpoint, body, header, apiUrl);
}
