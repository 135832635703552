import {
  UpiItemInterface,
  BankItemInterface,
  OrderHistoryInterface,
  TransactionHistoryInterface,
  AssetInterface,
  WalletAddressListInterface,
} from './../../../typing/interface';
import { createAction, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  actionAssetList,
  actionAssetPriceUpdate,
  actionFiatList,
  actionOrderHistory,
  actionTransectionHistory,
  actionWalletAddressList,
} from '../action/CommonAction';
import { PageType } from '../../utils/constants';

interface InitialState {
  curPage: string;
  lastPage: string;
  fiatList: object[];
  selectedFiat: null | {
    id: number;
    name: string;
    symbol: string;
    network: string;
    min_buy: number;
    act_min_buy: number;
    max_decimal_places: number;
    min_decimal_places: number;
    inr_price: string;
    usdt_price: string;
    img_url: string;
    change24Hr: string;
  };
  fiatListPending: boolean;
  fiatListError: boolean;
  assetList: AssetInterface[];
  selectedAsset: null | {
    id: number;
    name: string;
    symbol: string;
    network: string;
    min_buy: number;
    act_min_buy: number;
    max_decimal_places: number;
    min_decimal_places: number;
    inr_price: string;
    usdt_price: string;
    img_url: string;
    change24Hr: string;
  };
  assetListPending: boolean;
  assetListError: boolean;
  userPlacedOrder: {
    fiat_amt: number;
    asset_amt: number;
    asset: string;
    network: string;
    fiat_curr: string;
    total_fees: number;
  } | null;
  userWalletAddress: WalletAddressListInterface | null;
  curAssetPrice: number | null;
  curAssetPricePending: boolean;
  curPriceTrigger: boolean;
  selectedBank: BankItemInterface | null;
  selectedUpi: UpiItemInterface | null;
  orderHistory: OrderHistoryInterface[] | null;
  orderHistoryPending: boolean;
  orderHistoryError: boolean;
  transactionHistory: TransactionHistoryInterface[] | null;
  transactionHistoryPending: boolean;
  transactionHistoryError: boolean;
  orderEndTime: null | string;
  orderStartTime: null | string;
  orderFees: {
    carret_fee: number;
    network_fee: number;
  };
  walletAddressList: WalletAddressListInterface[];
  walletAddressListPending: boolean;
  walletAddressListError: boolean;
  orderTimeEnded: boolean;
}

const initialState: InitialState = {
  curPage: PageType.HOME_PAGE,
  lastPage: PageType.HOME_PAGE,
  fiatList: [],
  selectedFiat: null,
  fiatListPending: false,
  fiatListError: true,
  assetList: [],
  selectedAsset: null,
  assetListPending: false,
  assetListError: true,
  userPlacedOrder: null,
  userWalletAddress: null,
  curAssetPrice: null,
  curAssetPricePending: false,
  curPriceTrigger: false,
  selectedBank: null,
  selectedUpi: null,
  orderHistory: null,
  orderHistoryPending: false,
  orderHistoryError: false,
  transactionHistory: null,
  transactionHistoryPending: false,
  transactionHistoryError: false,
  orderEndTime: null,
  orderStartTime: null,
  orderFees: {
    carret_fee: 0,
    network_fee: 0,
  },
  walletAddressList: [],
  walletAddressListPending: false,
  walletAddressListError: false,
  orderTimeEnded: false,
};
const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    saveSelectedFiat: (state, action: PayloadAction<any>) => {
      state.selectedFiat = action.payload;
    },
    saveSelectedAsset: (state, action: PayloadAction<any>) => {
      state.selectedAsset = action.payload;
    },
    savePageType: (state, action: PayloadAction<any>) => {
      state.lastPage = state.curPage;
      state.curPage = action.payload;
    },
    saveUserPlacedOrderDetails: (state, action: PayloadAction<any>) => {
      state.userPlacedOrder = action.payload;
    },
    saveWalletAddress: (state, action: PayloadAction<any>) => {
      state.userWalletAddress = action.payload;
    },
    saveCurPriceTrigger: (state, action: PayloadAction<any>) => {
      state.curPriceTrigger = action.payload;
    },
    saveSelectedBank: (state, action: PayloadAction<any>) => {
      state.selectedBank = action.payload;
    },
    saveSelectedUpi: (state, action: PayloadAction<any>) => {
      state.selectedUpi = action.payload;
    },
    saveOrderTime: (state, action: PayloadAction<any>) => {
      state.orderEndTime = action.payload.endTime;
      state.orderStartTime = action.payload.now;
    },
    saveOrderFees: (state, action: PayloadAction<any>) => {
      state.orderFees = action.payload;
    },
    saveResetCommonState: (state, action: PayloadAction<any>) => {
      state = initialState;
    },
    saveOrderTimeStatus: (state, action: PayloadAction<any>) => {
      state.orderTimeEnded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionFiatList.pending, (state) => {
        state.fiatListPending = true;
      })
      .addCase(actionFiatList.fulfilled, (state, { payload }) => {
        state.fiatList = payload?.results;
        if (
          !state.selectedFiat &&
          payload?.results &&
          payload?.results.length
        ) {
          state.selectedFiat = payload.results[0];
        }
        state.fiatListPending = false;
      })
      .addCase(actionFiatList.rejected, (state, { payload }) => {
        state.fiatListPending = false;
        state.fiatListError = true;
      });
    builder
      .addCase(actionAssetList.pending, (state) => {
        state.assetListPending = true;
      })
      .addCase(actionAssetList.fulfilled, (state, { payload }) => {
        state.assetList = payload?.results;
        if (
          !state.selectedAsset &&
          payload?.results &&
          payload?.results.length
        ) {
          state.selectedAsset = payload.results[0];
        }
        state.assetListPending = false;
      })
      .addCase(actionAssetList.rejected, (state, { payload }) => {
        state.assetListPending = false;
        state.assetListError = true;
      });
    builder
      .addCase(actionAssetPriceUpdate.pending, (state) => {
        state.curAssetPricePending = true;
      })
      .addCase(actionAssetPriceUpdate.fulfilled, (state, { payload }) => {
        state.curAssetPrice = Number(payload.price) || null;
        state.curAssetPricePending = false;
      })
      .addCase(actionAssetPriceUpdate.rejected, (state, { payload }) => {
        state.curAssetPricePending = false;
      });
    builder
      .addCase(actionOrderHistory.pending, (state) => {
        state.orderHistoryPending = true;
      })
      .addCase(actionOrderHistory.fulfilled, (state, { payload }) => {
        state.orderHistory = payload.results;
        state.orderHistoryPending = false;
      })
      .addCase(actionOrderHistory.rejected, (state, { payload }) => {
        state.orderHistoryPending = false;
        state.orderHistoryError = true;
      });
    builder
      .addCase(actionTransectionHistory.pending, (state) => {
        state.transactionHistoryPending = true;
      })
      .addCase(actionTransectionHistory.fulfilled, (state, { payload }) => {
        state.transactionHistory = payload.results;
        state.transactionHistoryPending = false;
      })
      .addCase(actionTransectionHistory.rejected, (state, { payload }) => {
        state.transactionHistoryPending = false;
        state.transactionHistoryError = true;
      });
    builder
      .addCase(actionWalletAddressList.pending, (state) => {
        state.walletAddressListPending = true;
      })
      .addCase(actionWalletAddressList.fulfilled, (state, { payload }) => {
        state.walletAddressList = payload.results;
        state.walletAddressListPending = false;
      })
      .addCase(actionWalletAddressList.rejected, (state, { payload }) => {
        state.walletAddressListPending = false;
        state.walletAddressListError = true;
      });
    builder.addCase(resetCommonState, () => initialState);
  },
});
export const {
  saveSelectedFiat,
  saveSelectedAsset,
  savePageType,
  saveUserPlacedOrderDetails,
  saveWalletAddress,
  saveCurPriceTrigger,
  saveSelectedBank,
  saveSelectedUpi,
  saveOrderTime,
  saveOrderFees,
  saveResetCommonState,
  saveOrderTimeStatus,
} = commonSlice.actions;
export default commonSlice.reducer;

export const resetCommonState = createAction('resetCommonState');
