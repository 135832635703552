import React, { useState } from 'react';
import { AddUpi } from './Chunks/AddUpi';
import { AddBank } from './Chunks/AddBank';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import ReactImage from '../../components/ReactImage/ReactImage';
import { assetObject } from '../../utils/assetFileName';
import { SvgIcon } from '../../components/ReactImage/SvgIcon';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';

type SelectedPayState = 'UPI' | 'BANK' | 'CARD';

const AddPaymentLayout = () => {
  const [selectedPay, setSelectedPay] = useState<SelectedPayState>('UPI');
  const dispatch = useAppDispatch();
  const { lastPage } = useAppSelector((state) => state.common);

  return (
    <section className="p-4">
      <FluidHeader
        title="New Payment Options"
        onClickHandler={() => dispatch(savePageType(lastPage))}
      />
      <div className="flex bg-green-100 rounded-xl justify-between">
        <div
          className={`flex-grow p-4 flex flex-col items-center gap-1 transition-all duration-300 hover:bg-green-500 hover:text-white hover:z-10 transform hover:scale-110 rounded-lg ${
            selectedPay === 'UPI'
              ? 'bg-green-500 text-white scale-110 z-10'
              : 'bg-green-100 text-black'
          }`}
          onClick={() => setSelectedPay('UPI')}
        >
          <div className="bg-white p-6 rounded-full relative">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[100%]">
              <ReactImage
                src={assetObject.upiIcon}
                width={40}
                height={40}
                alt="icon"
              />
            </div>
          </div>
          <p>UPI</p>
        </div>
        <div
          onClick={() => setSelectedPay('BANK')}
          className={`flex-grow p-4 flex flex-col items-center gap-1 transition-all duration-300 hover:bg-green-500 hover:text-white hover:z-10 transform hover:scale-110 rounded-lg ${
            selectedPay === 'BANK'
              ? 'bg-green-500 text-white scale-110 z-10'
              : 'bg-green-100 text-black'
          }`}
        >
          <div className="bg-white p-6 rounded-full relative">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <SvgIcon
                svgString={assetObject.bankIcon}
                width={40}
                height={40}
                className="flex items-center justify-center"
              />
            </div>
          </div>
          <p>Bank A/C</p>
        </div>
        <div
          // onClick={() => setSelectedPay('CARD')}
          // cursor-not-allowed
          className={`flex-grow p-4 flex flex-col items-center gap-1 rounded-lg cursor-not-allowed ${
            selectedPay === 'CARD'
              ? 'bg-green-500 text-white scale-110 z-10'
              : 'bg-green-100 text-black'
          }`}
        >
          <div className="bg-white p-6 rounded-full relative">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <ReactImage
                src={assetObject.cardIcon}
                width={40}
                height={40}
                alt="icon"
              />
            </div>
          </div>
          <p>Card</p>
        </div>
      </div>
      {selectedPay === 'UPI' ? <AddUpi /> : <AddBank />}
    </section>
  );
};

export default AddPaymentLayout;
