import React, { FC, useRef, useState } from 'react';
import ReactImage from '../../../components/ReactImage/ReactImage';
import { assetObject } from '../../../utils/assetFileName';
import { FluidButton } from '../../../components/FluidButton/FluidButton';
import { postRasieRequest } from '../../../utils/api';

const RaiseRequest: FC = () => {
  const [message, setMessage] = useState('');
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = () => {
    const file = fileInputRef.current?.files?.[0];

    if (file) {
      setFileName(file.name);
    }
  };

  const submitRequest = async () => {
    const file = fileInputRef.current?.files?.[0];

    const formData = new FormData();
    formData.append('issue_file', file as Blob);
    formData.append('issue_description', message);

    try {
      const res = await postRasieRequest(formData, {
        'Content-Type': 'multipart/form-data',
      });
      console.log(res);
    } catch (error) {
      console.error('Error submitting request', error);
    }
  };

  return (
    <section>
      <p className="text-sm font-semibold">
        Describe the issue that you’re experiencing with the application.
      </p>
      <textarea
        className="w-[100%] border rounded-xl p-2 my-4 outline-none"
        rows={8}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      ></textarea>
      <div className="rounded-xl border border-[var(--primary-green)] p-1">
        <div className="border border-dashed border-[var(--primary-green)] p-[1px] rounded-xl">
          <button
            id="upload-button"
            onClick={handleFileUpload}
            className="w-[100%] text-sm text-[var(--primary-green)] p-2"
          >
            <div className="flex gap-1 justify-center items-center">
              <ReactImage
                src={assetObject.uploadIcon}
                height={16}
                width={16}
                alt="upload img"
              />
              <p>Upload File</p>
            </div>
          </button>
          <input
            className="hidden"
            ref={fileInputRef}
            id="file-upload"
            type="file"
            accept=".jpg, .jpeg, .png"
            onChange={handleFileChange}
          />
          <p className="text-sm">{fileName}</p>
        </div>
      </div>
      <div className="mt-4">
        <FluidButton onClickHandler={submitRequest}>Submit</FluidButton>
      </div>
    </section>
  );
};

export default RaiseRequest;
