import React, { useState } from 'react';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { EnterOtp } from './Chunks/EnterOtp';
import { EnterEmail } from './Chunks/EnterEmail';
import { useAppDispatch } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';

const Login = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useAppDispatch();

  const backBtnHandler = (): void => {
    if (otpSent) {
      setOtpSent(false);
    } else {
      dispatch(savePageType(PageType.HOME_PAGE));
    }
  };

  return (
    <main className="p-4">
      <FluidHeader
        title={otpSent ? 'Confirm OTP' : 'Enter Email'}
        onClickHandler={backBtnHandler}
      />
      {otpSent ? (
        <EnterOtp email={email} />
      ) : (
        <EnterEmail setOtpSent={setOtpSent} setEmail={setEmail} />
      )}
    </main>
  );
};

export default Login;
