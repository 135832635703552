import React, { useState } from 'react';
import ReactImage from '../../components/ReactImage/ReactImage';
import { assetObject } from '../../utils/assetFileName';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import FluidModal from '../../components/FluidModal/FluidModal';
import { addrListInterface } from '../../../typing/interface';
import moment from 'moment';
import { deleteWalletAddressApi } from '../../utils/api';

interface AddressCardProp {
  data: addrListInterface;
  setUpdateList: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddressCard: React.FC<AddressCardProp> = ({
  data,
  setUpdateList,
}) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteAddress = async () => {
    try {
      const res = await deleteWalletAddressApi(data.id);
      setDeleteModal(false);
    } catch (error) {
      console.log(error);
    } finally {
      setUpdateList((pre) => !pre);
    }
  };

  return (
    <div className="rounded-xl custom-shadow">
      <div className="bg-[var(--light-green)] p-2 px-4 font-semibold">
        {data.address_label}
      </div>
      <div className="px-4 py-2 grid grid-cols-2 gap-2">
        <div>
          <p className="font-semibold">Asset</p>
          <p>{data?.asset?.symbol}</p>
        </div>
        <div>
          <p className="font-semibold">Network</p>
          <p>{data.network}</p>
        </div>
        <div>
          <p className="font-semibold">Added on</p>
          <p>{moment(data.created_at).format('DD MMMM YYYY')}</p>
        </div>
        <div>
          <p className="font-semibold">Status</p>
          <p>{data.status}</p>
        </div>
        <div>
          <p className="font-semibold">Platform</p>
          <p>{data.platform}</p>
        </div>
        <div>
          <p className="font-semibold">Address</p>
          <div className="flex justify-between">
            <p className="truncate w-70%">{data.address}</p>
            <button>
              <ReactImage
                src={assetObject.copySvg}
                alt="copy img"
                height={20}
                width={20}
                className="w-30%"
              />
            </button>
          </div>
        </div>
      </div>
      <div className="p-4">
        <FluidButton
          variant="outlined"
          color="error"
          onClickHandler={() => setDeleteModal(true)}
        >
          Delete
        </FluidButton>
      </div>
      <FluidModal
        modalStatus={deleteModal}
        onModalClose={() => setDeleteModal(false)}
      >
        <div className="flex flex-col">
          <p className="font-semibold text-center py-4">
            Are you sure you want to delete this address?
          </p>
          <div className="flex gap-2 p-2">
            <FluidButton
              variant="outlined"
              onClickHandler={() => setDeleteModal(false)}
            >
              Cancel
            </FluidButton>
            <FluidButton variant="contained" onClickHandler={deleteAddress}>
              Delete
            </FluidButton>
          </div>
        </div>
      </FluidModal>
    </div>
  );
};
