import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { saveSelectedAsset } from '../../redux/reducer/CommonReducer';
import { AssetInterface } from '../../../typing/interface';

interface assetProps {
  assetList: AssetInterface[];
  showFees?: boolean;
}

const AssetList: React.FC<assetProps> = ({
  assetList = [],
  showFees = true,
}) => {
  const { selectedAsset } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();

  return (
    <div className="px-3">
      <h2 className="text-lg font-semibold pb-2">Select Asset</h2>
      <div className="overflow-x-auto h-96">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="">
            <tr>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                Asset
              </th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                Network
              </th>
              {showFees && (
                <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Fees
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {assetList.map((item: AssetInterface) => (
              <tr
                key={item.symbol + item.network}
                onClick={() => dispatch(saveSelectedAsset(item))}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id={item.symbol}
                      name="asset"
                      value={item.symbol}
                      checked={item.id === selectedAsset?.id}
                      className="mr-2"
                      onChange={() => dispatch(saveSelectedAsset(item))}
                    />
                    <img
                      src={item.img_url || undefined}
                      alt="fiat icon"
                      className="h-5 w-5"
                    />
                    <span className="ml-2 text-sm">
                      {item.symbol} ({item.name})
                    </span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{item.network}</td>
                {showFees && (
                  <td className="px-6 py-4 whitespace-nowrap">0.2%</td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AssetList;
