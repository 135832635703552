import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { actionUserDetails } from '../action/AuthAction';
import { UserInfoInterface } from '../../../typing/interface';

interface InitialState {
  userInfo: UserInfoInterface | null;
  userInfoPending: boolean;
  userInfoError: boolean;
  loginError: boolean;
  loginPending: boolean;
  isUserLogedIn: boolean;
}

const initialState: InitialState = {
  userInfo: null,
  userInfoPending: false,
  userInfoError: false,
  loginPending: false,
  loginError: false,
  isUserLogedIn: false,
};
const authSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    saveUserLoginState: (state, action: PayloadAction<any>) => {
      state.isUserLogedIn = action.payload;
    },
    saveLogoutUser: (state) => {
      state.isUserLogedIn = false;
      state.userInfo = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionUserDetails.pending, (state) => {
        state.userInfoPending = true;
      })
      .addCase(actionUserDetails.fulfilled, (state, { payload }) => {
        state.userInfo = payload?.results[0];
        state.userInfoPending = false;
      })
      .addCase(actionUserDetails.rejected, (state, { payload }) => {
        state.userInfoPending = false;
        state.userInfoError = true;
      });
  },
});
export const { saveUserLoginState, saveLogoutUser } = authSlice.actions;
export default authSlice.reducer;
