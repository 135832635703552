import React, { useEffect, useState } from 'react';
import ReactImage from '../../components/ReactImage/ReactImage';
import { assetObject } from '../../utils/assetFileName';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  resetCommonState,
  savePageType,
} from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';
import useOrderHook from '../../hooks/useOrderHook';
import { roundNumber } from '../../utils';
import FluidModal from '../../components/FluidModal/FluidModal';

export const OrderDetails = () => {
  const { selectedAsset, userPlacedOrder, userWalletAddress, orderTimeEnded } =
    useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();

  const [verifyCancelModal, setVerifyCancelModal] = useState(false);

  const submitHandler = () => {
    dispatch(savePageType(PageType.SELECT_PAYMENT_MODE));
  };

  const onCancelHandler = () => {
    dispatch(savePageType(PageType.HOME_PAGE));
    dispatch(resetCommonState());
  };

  const { timer } = useOrderHook();

  return (
    <>
      <FluidHeader
        title="Order Details"
        onClickHandler={() => dispatch(savePageType(PageType.WALLET_ID))}
      />
      <section className="p-4">
        {orderTimeEnded && (
          <p className="pb-4 font-semibold">
            Your time to confirm order has ended. If you proceed to confirm you
            will be charged according to the new price stated below.
          </p>
        )}
        <div className="border border-gray-300 rounded-lg">
          <div className="flex justify-between py-4 px-2 bg-[var(--light-green)] rounded-t-lg">
            <p className="text-lg font-semibold">Order details</p>
            <div className="text-sm flex gap-1 items-center">
              <ReactImage src={assetObject.clockSvg} alt="clock icon" />
              {timer}
            </div>
          </div>
          <div className="flex justify-between p-2">
            <div>
              <p className="text-sm font-light">Asset</p>
              <div className="flex items-center gap-1">
                <ReactImage
                  src={selectedAsset?.img_url || ''}
                  alt="clock icon"
                  height={20}
                  width={20}
                />
                <p className="font-semibold">{selectedAsset?.symbol}</p>
              </div>
            </div>
            <div>
              <p className="text-sm font-light text-right">Network</p>
              <div className="flex items-center gap-1">
                {/* <ReactImage
                  src={assetObject.clockSvg}
                  alt="clock icon"
                  height={20}
                  width={20}
                /> */}
                <p className="font-semibold">{selectedAsset?.network}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between p-2">
            <div>
              <p className="text-sm font-light">Quantity received</p>
              <div className="flex items-center gap-1">
                <ReactImage
                  src={assetObject.clockSvg}
                  alt="clock icon"
                  height={20}
                  width={20}
                />
                <p className="font-semibold">
                  {roundNumber(
                    userPlacedOrder?.asset_amt || 0,
                    selectedAsset?.min_decimal_places,
                  )}{' '}
                  {userPlacedOrder?.asset}
                </p>
              </div>
            </div>
            <div>
              <p className="text-sm font-light text-right">Amount to be paid</p>
              <div className="flex items-left justify-end gap-1">
                {/* <ReactImage
                  src={assetObject.clockSvg}
                  alt="clock icon"
                  height={20}
                  width={20}
                /> */}
                <p className="font-semibold">₹ {userPlacedOrder?.fiat_amt}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-between p-2">
            <div>
              <p className="text-sm font-light">Wallet ID</p>
              <div className="flex items-center gap-1">
                <p className="font-semibold truncate w-40">
                  {userWalletAddress?.address}
                </p>
                <button>
                  <ReactImage
                    src={assetObject.copySvg}
                    alt="copy icon"
                    height={20}
                    width={20}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-2 w-[100%] py-4">
          <FluidButton
            variant="outlined"
            color="error"
            onClickHandler={() => setVerifyCancelModal(true)}
          >
            Cancel
          </FluidButton>
          <FluidButton variant="contained" onClickHandler={submitHandler}>
            Submit
          </FluidButton>
        </div>
      </section>
      <FluidModal
        modalStatus={verifyCancelModal}
        onModalClose={() => setVerifyCancelModal(false)}
      >
        <div className="flex flex-col">
          <p className="font-semibold text-center py-4">
            Are you sure you want to cancel this order?
          </p>
          <div className="flex gap-2 p-2">
            <FluidButton
              variant="outlined"
              onClickHandler={() => setVerifyCancelModal(false)}
            >
              No
            </FluidButton>
            <FluidButton variant="contained" onClickHandler={onCancelHandler}>
              Yes
            </FluidButton>
          </div>
        </div>
      </FluidModal>
    </>
  );
};
