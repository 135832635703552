import React from 'react';
import ReactImage from '../../components/ReactImage/ReactImage';
import { assetObject } from '../../utils/assetFileName';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import { useDispatch } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';

const AppPaymentPlaceholder = () => {
  const dispatch = useAppDispatch();

  const addPaymentClickHandler = () => {
    dispatch(savePageType(PageType.ADD_PAYMENT_METHOD));
  };
  return (
    <div className="flex flex-col items-center gap-3 p-4 mt-20">
      <ReactImage
        src={assetObject.addPaymentPlaceholder}
        height={20}
        width={90}
        alt="place_holder"
      />
      <p>Please add a payment method to continue</p>
      <FluidButton onClickHandler={addPaymentClickHandler}>
        Add Payment Method
      </FluidButton>
    </div>
  );
};

export default AppPaymentPlaceholder;
