import {
  OrderInterface,
  TransactionInterface,
  UpdateAssetPricePayloadInterface,
  WalletAddressPayloadInterface,
} from './../../../typing/interface';
import { del, get, patch, post } from './request';

export const postLogin = (payload: { email: string }) =>
  post('auth/login', payload);

export const postVerifyOpt = (payload: { email: string; otp: string }) =>
  post('auth/verify_otp', payload);

export const postRefreshToken = (payload: { refresh_token: string }) =>
  post('auth/refresh_token', payload);

export const getUserDetails = () => get('account/account');

export const getCalculatedFees = (payload: {
  fiat_amt: number;
  asset_amt: number;
  asset: string;
  network: string;
  fiat_curr: string;
}) => post('exchange/calculate_fee/', payload);

export const getFiatList = () => get('exchange/fiat_currency/');

export const postValidateUpi = (payload: { upi_id: string; name: string }) =>
  post('kyc/validate_upi_id', payload);

export const postAddUpi = (payload: { upi_id: string; name: string }) =>
  post('kyc/upi_id/', payload);

export const postAddBank = (payload: {
  acc_name: string;
  bank_name: string;
  acc_no: string;
  ifsc: string;
}) => post('kyc/bank/', payload);

export const getAssetList = (onChainActive: boolean) =>
  get(
    `exchange/assets/?page_size=100?is_onchain_active${onChainActive ? 'true' : ''}`,
  );

export const getUpiList = () => get('kyc/upi_id/');

export const getBankList = () => get('kyc/bank/');

export const deleteAccRequest = (payload: { reason: string }) =>
  post('account/delete_account', payload);

export const postRasieRequest = (payload: any, headers: any) =>
  post('account/raise_issue', payload, headers);

export const getAssetPrice = ({
  payload,
  side,
}: UpdateAssetPricePayloadInterface) =>
  post(`exchange/price/?side=${side}`, payload);

export const postWalletAddress = (payload: WalletAddressPayloadInterface) =>
  post('payments/whitelist_crypto_address/', payload);

export const getWalletList = (status = '') =>
  get(`payments/whitelist_crypto_address/?status=${status}`);

export const updateUserProfileApi = (payload: any, accId: number) =>
  patch(`account/account_update/${accId}`, payload);

export const getOrderHistoryApi = () => get('exchange/order/');

export const getTransectionHistory = () => get('payments/transaction/');

export const deleteWalletAddressApi = (id: number) =>
  del(`payments/whitelist_crypto_address/${id}`);

export const addWalletAddressApi = (payload: {
  asset: number;
  network: string;
  address: string;
  platform: string;
  address_label: string;
}) => post('payments/whitelist_crypto_address/', payload);

export const verifyKycApi = (payload: {
  transaction_id: string;
  application_status: string;
}) => post('kyc/verify_kyc/', payload);

export const missingDepositClaimApi = (payload: {
  amount: string;
  utr_no: string;
  fiat_ccy: string;
  asset: number;
  network: string;
  wallet: number;
}) => post('payments/missing_deposit_claim/', payload);

export const createTxnOrderApi = (payload: {
  order: OrderInterface;
  txn: TransactionInterface;
}) => post('payments/order_txn/', payload);

export const getHpvToken = () => get('kyc/hyperverge_token/');
