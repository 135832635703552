import React from 'react';
import { createRoot, Root } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';

declare global {
  interface Window {
    FluidPaySdk: (containerId: string, props: Record<string, any>) => void;
    unmountFluidPaySdk: (containerId: string) => void;
  }
}

const roots: { [key: string]: Root } = {};

window.FluidPaySdk = (containerId: string, props: Record<string, any>) => {
  const container = document.getElementById(containerId);
  if (container) {
    const root = createRoot(container);
    roots[containerId] = root;
    console.log(containerId);
    localStorage.setItem(containerId, containerId);
    localStorage.setItem('prop', String(props));

    root.render(
      <Provider store={store}>
        <App {...props} />
      </Provider>,
    );
  }
};

window.unmountFluidPaySdk = (containerId: string) => {
  const root = roots[containerId];
  if (root) {
    root.unmount();
    delete roots[containerId];
  }
};
