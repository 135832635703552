import React, { MouseEvent, useEffect, useState } from 'react';
import { assetObject } from '../../utils/assetFileName';
import { SvgIcon } from '../ReactImage/SvgIcon';
import styles from './header.module.scss';
import SideSlider from '../SideSlider/SideSlider';
import { useAppSelector } from '../../redux/store';
import { PageType } from '../../utils/constants';
import useOrderHook from '../../hooks/useOrderHook';

interface FluidHeaderProps {
  title?: string;
  onClickHandler?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const FluidHeader: React.FC<FluidHeaderProps> = ({
  title = 'Fluid',
  onClickHandler,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { curPage } = useAppSelector((state) => state.common);

  const backBtnClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClickHandler) {
      onClickHandler(event);
    }
  };

  useOrderHook();

  return (
    <>
      <nav className={styles.header}>
        {curPage === PageType.HOME_PAGE ? (
          <button className={styles.fbtn} onClick={() => setIsOpen(true)}>
            <SvgIcon svgString={assetObject.burgerIcon} />
          </button>
        ) : (
          <button className={styles.fbtn} onClick={backBtnClick}>
            <SvgIcon svgString={assetObject.backIcon} />
          </button>
        )}
        <div className={styles.title}>{title}</div>
        <div></div>
      </nav>
      <div className="py-6" />
      <SideSlider isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};
