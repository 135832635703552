import React, { useState } from 'react';
import ReactImage from '../../../components/ReactImage/ReactImage';
import { assetObject } from '../../../utils/assetFileName';
import RaiseRequest from './RaiseRequest';
import { FluidHeader } from '../../../components/FluidHeader/FluidHeader';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { savePageType } from '../../../redux/reducer/CommonReducer';
import { PageType } from '../../../utils/constants';

export const SupportList = () => {
  const [raiseRequest, setRasieRequest] = useState(false);
  const dispatch = useAppDispatch();

  if (raiseRequest) {
    return (
      <>
        <FluidHeader
          title="Raise A Request"
          onClickHandler={() => setRasieRequest(false)}
        />
        <RaiseRequest />;
      </>
    );
  }
  return (
    <>
      <FluidHeader
        title="Support"
        onClickHandler={() => dispatch(savePageType(PageType.HOME_PAGE))}
      />
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between border rounded-xl p-3 cursor-pointer">
          <div className="flex items-center gap-2">
            <ReactImage
              src={assetObject.telegramIcon}
              height={20}
              width={20}
              alt="Telegram img"
            />
            <p className="text-sm">Telegram</p>
          </div>
          <ReactImage src={assetObject.rightArrow} alt="rightArrow img" />
        </div>
        <div className="flex items-center justify-between border rounded-xl p-3 cursor-pointer">
          <div className="flex items-center gap-2">
            <ReactImage
              src={assetObject.wtspIcon}
              height={20}
              width={20}
              alt="Whatsapp img"
            />
            <p className="text-sm">Whatsapp</p>
          </div>
          <ReactImage src={assetObject.rightArrow} alt="rightArrow img" />
        </div>
        <div
          className="flex items-center justify-between border rounded-xl p-3 cursor-pointer"
          onClick={() => setRasieRequest(true)}
        >
          <div className="flex items-center gap-2">
            <ReactImage
              src={assetObject.reqIcon}
              height={20}
              width={20}
              alt="Raise a request img"
            />
            <p className="text-sm">Raise a request</p>
          </div>
          <ReactImage src={assetObject.rightArrow} alt="rightArrow img" />
        </div>
        <div className="flex items-center justify-between border rounded-xl p-3 cursor-pointer">
          <div className="flex items-center gap-2">
            <ReactImage
              src={assetObject.mailIcon}
              height={20}
              width={20}
              alt="Email img"
            />
            <p className="text-sm">Email</p>
          </div>
          <ReactImage src={assetObject.rightArrow} alt="rightArrow img" />
        </div>
      </div>
    </>
  );
};
