import React, { FormEvent, useState } from 'react';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { assetObject } from '../../utils/assetFileName';
import ReactImage from '../../components/ReactImage/ReactImage';
import { FluidInput } from '../../components/FluidInput/FluidInput';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import useOrderHook from '../../hooks/useOrderHook';
import FluidModal from '../../components/FluidModal/FluidModal';
import { StatusModal } from '../../components/StatusModal/StatusModal';
import {
  resetCommonState,
  savePageType,
} from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';

const AddUpiUtrLayout = () => {
  const { selectedUpi, userPlacedOrder } = useAppSelector(
    (state) => state.common,
  );

  const dispatch = useAppDispatch();
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(true);
  const [verifyCancelModal, setVerifyCancelModal] = useState(false);

  const { placeOrderTxn } = useOrderHook();

  const formSubmithHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const utr = (event.target as HTMLFormElement).utr_no.value;
    try {
      await placeOrderTxn(utr);
      setOrderSuccess(true);
    } catch (error) {
      console.log(error);
      setOrderSuccess(false);
    } finally {
      setOpenStatusModal(true);
    }
  };

  const onModalClose = () => {
    dispatch(resetCommonState());
    dispatch(savePageType(PageType.HOME_PAGE));
  };

  return (
    <>
      <FluidHeader />
      <section className="p-4">
        <p className="font-semibold pb-2">
          Please transfer the funds via your UPI app and fill in the UTR number
          below.
        </p>
        <div className="border border-gray-300 rounded-lg">
          <div className="flex justify-between py-4 px-2 bg-[var(--light-green)] rounded-t-lg">
            <p className="text-lg font-semibold">UPI Details</p>
          </div>
          <div className="p-2">
            <div>
              <p className="text-sm font-light">UPI ID</p>
              <div className="flex items-center gap-1">
                <p className="font-semibold">asset</p>
                <ReactImage
                  src={assetObject.copySvg}
                  alt="copy icon"
                  height={20}
                  width={20}
                />
              </div>
            </div>
            <div>
              <p className="text-sm font-light">Account Holder</p>
              <div className="flex items-center gap-1">
                <p className="font-semibold">asset</p>
                <ReactImage
                  src={assetObject.copySvg}
                  alt="copy icon"
                  height={20}
                  width={20}
                />
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={formSubmithHandler}>
          <div className="py-2">
            <FluidInput
              inputName="utr_no"
              label="UTR number of transaction"
              required
            />
          </div>
          <div className="border rounded-xl border-orange-300 text-xs font-semibold p-1 mt-4 flex gap-2 items-center">
            <span>
              <ReactImage
                src={assetObject.iSvg}
                alt="isvg"
                height={30}
                width={30}
              />
            </span>
            <div className="text-orange-300">
              Please deposit only using {selectedUpi?.upi_id} UPI.
            </div>
          </div>
          <div className="border border-gray-300 rounded-lg text-xs font-semibold p-1 mt-4 flex gap-2 items-center">
            <span>
              <ReactImage
                src={assetObject.cashIcon}
                alt="isvg"
                height={30}
                width={30}
              />
            </span>
            <div className="color-primary">
              You will be depositing {userPlacedOrder?.fiat_amt} INR
            </div>
          </div>
          <div className="flex gap-2 w-[100%] py-4">
            <FluidButton
              variant="outlined"
              color="error"
              onClickHandler={() => setVerifyCancelModal(true)}
            >
              Cancel
            </FluidButton>
            <FluidButton variant="contained" buttonType="submit">
              Submit
            </FluidButton>
          </div>
        </form>
        <FluidModal modalStatus={openStatusModal} onModalClose={onModalClose}>
          <StatusModal
            statusType={orderSuccess ? 'success' : 'error'}
            text={orderSuccess ? 'success' : 'error'}
          />
        </FluidModal>
        <FluidModal
          modalStatus={verifyCancelModal}
          onModalClose={() => setVerifyCancelModal(false)}
        >
          <div className="flex flex-col">
            <p className="font-semibold text-center py-4">
              Are you sure you want to cancel this order?
            </p>
            <div className="flex gap-2 p-2">
              <FluidButton
                variant="outlined"
                onClickHandler={() => setVerifyCancelModal(false)}
              >
                No
              </FluidButton>
              <FluidButton variant="contained" onClickHandler={onModalClose}>
                Yes
              </FluidButton>
            </div>
          </div>
        </FluidModal>
      </section>
    </>
  );
};

export default AddUpiUtrLayout;
