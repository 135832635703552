import React, { useState } from 'react';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import { deleteAccRequest } from '../../utils/api';
import FluidModal from '../../components/FluidModal/FluidModal';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';

const DeleteAccountLayout = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [resetTxtArea, setResetTxtArea] = useState(false);

  const dispatch = useAppDispatch();

  const deleteAccHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const reason = (event.target as HTMLFormElement).reason.value;
      const res = await deleteAccRequest({ reason: reason });
      setDeleteModal(true);
    } catch (error) {
      console.log(error);
    } finally {
      setResetTxtArea(!resetTxtArea);
    }
  };

  return (
    <main className="p-4">
      <FluidHeader
        title="Delete Account"
        onClickHandler={() => dispatch(savePageType(PageType.HOME_PAGE))}
      />
      <form onSubmit={deleteAccHandler}>
        <section>
          <p className="text-sm font-semibold">
            Please tell us your reason for account deletion.
          </p>
          <textarea
            key={resetTxtArea ? '1' : '2'}
            className="w-[100%] border rounded-xl p-2 my-4 outline-none"
            rows={8}
            required
            name="reason"
          ></textarea>

          <div className="mt-1">
            <FluidButton buttonType="submit" color="error">
              Delete
            </FluidButton>
          </div>
        </section>
      </form>
      <FluidModal
        cancelIconEnable
        modalStatus={deleteModal}
        onModalClose={() => setDeleteModal(false)}
      >
        <div className="flex flex-col items-center gap-4 py-4">
          <p className="text-lg font-semibold">Delete Account</p>
          <p className="text-center">
            Your request for account deletion has been placed. <br />
            Team will reach out to you shortly.
          </p>
          <div className="w-1/2">
            <FluidButton
              variant="contained"
              color="error"
              onClickHandler={() => setDeleteModal(false)}
            >
              Okay
            </FluidButton>
          </div>
        </div>
      </FluidModal>
    </main>
  );
};

export default DeleteAccountLayout;
