import React, { MouseEvent, ReactNode } from 'react';
import styles from './fluidModal.module.scss';
import ReactImage from '../ReactImage/ReactImage';
import { assetObject } from '../../utils/assetFileName';

interface FluidModalProps {
  children: ReactNode;
  modalStatus?: boolean; // Move the default value to the interface
  onModalClose?: () => void;
  cancelIconEnable?: boolean;
}

const FluidModal: React.FC<FluidModalProps> = ({
  children,
  modalStatus = false, // Use the default value from the interface
  onModalClose,
  cancelIconEnable = false,
}) => {
  const handleModalClose = (
    event: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>,
  ) => {
    // Check if the click event originated from the close button
    if ((event.target as HTMLElement).nodeName !== 'BUTTON' && onModalClose) {
      onModalClose();
    }
  };

  return modalStatus ? (
    <div className={styles.modalMain} onClick={handleModalClose}>
      <div className="p-2 w-[100%] rounded-xl bg-white relative z-20">
        {cancelIconEnable && (
          <button
            className="absolute top-[12px] right-[14px]"
            onClick={(event) => handleModalClose(event)}
          >
            <ReactImage
              alt="cancel-icon"
              src={assetObject.cancelSvg}
              height={15}
              width={15}
            />
          </button>
        )}
        {children}
      </div>
    </div>
  ) : (
    <></>
  ); // Use null for conditional rendering
};

export default FluidModal;
