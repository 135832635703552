import React, { useEffect } from 'react';
import './styles/global.scss';
import PurchaseLayout from './Layout/PurchaseLayout/PurchaseLayout';
import { useAppSelector, useAppDispatch } from './redux/store';
import { PageType } from './utils/constants';
import Login from './Layout/Login';
import KycLayout from './Layout/KycLayout/KycLayout';
import { actionUserDetails } from './redux/action/AuthAction';
import { getAuthToken } from './utils';
import { saveUserLoginState } from './redux/reducer/AuthReducer';
import { WalletId } from './Layout/WalletIdLayout/WalletIdLayout';
import { OrderDetails } from './Layout/OrderDetailsLayout/OrderDetails';
import SelectPayModeLayout from './Layout/SelectPayModeLayout/SelectPayModeLayout';
import AddUtrBankLayout from './Layout/AddUtrBankLayout/AddUtrBankLayout';
import AddUpiUtrLayout from './Layout/AddUtrUpiLayout/AddUtrUpiLayout';
import AddPaymentLayout from './Layout/AddPayment/AddPaymentLayout';
import MissingDepositClaim from './Layout/MissingDepositClaim/MissingDepositClaim';
import SupportLayout from './Layout/Supportlayout/SupportLayout';
import DeleteAccountLayout from './Layout/DeleteAccountLayout/DeleteAccount';
import AdditionalInfoLayout from './Layout/AdditionalInfoLayout/AdditionalInfoLayout';
import AddWalletAddress from './Layout/AddWalletAddress/AddWalletAddress';
import { AddAddressLayout } from './Layout/AddWalletAddress/AddAddressLayout';
import OrderHistoryLayout from './Layout/OrderHistoryLayout/OrderHistoryLayout';
import TransactionHistoryLayout from './Layout/TransectionHistoryLayout/TransectionHistoryLayout';
import { Toaster } from 'react-hot-toast';

function App() {
  const { curPage } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();

  const ComponetSwitch = (curPage: string) => {
    switch (curPage) {
      case PageType.HOME_PAGE:
        return <PurchaseLayout />;
      case PageType.LOGIN_PAGE:
        return <Login />;
      case PageType.KYC_PAGE:
        return <KycLayout />;
      case PageType.WALLET_ID:
        return <WalletId />;
      case PageType.ORDER_DETAILS:
        return <OrderDetails />;
      case PageType.SELECT_PAYMENT_MODE:
        return <SelectPayModeLayout />;
      case PageType.ADD_UTR_BANK:
        return <AddUtrBankLayout />;
      case PageType.ADD_UTR_UPI:
        return <AddUpiUtrLayout />;
      case PageType.ADD_PAYMENT_METHOD:
        return <AddPaymentLayout />;
      case PageType.MISSING_DEPOSIT_CLAIM:
        return <MissingDepositClaim />;
      case PageType.SUPPORT:
        return <SupportLayout />;
      case PageType.DELETE_ACCOUNT:
        return <DeleteAccountLayout />;
      case PageType.ADDITIONAL_INFO:
        return <AdditionalInfoLayout />;
      case PageType.ADD_WALLET_ADDRESS:
        return <AddAddressLayout />;
      case PageType.ADDRESS_LIST:
        return <AddWalletAddress />;
      case PageType.TRANSACTION_HISTORY:
        return <TransactionHistoryLayout />;
      case PageType.ORDER_HISTORY:
        return <OrderHistoryLayout />;
      default:
        break;
    }
  };

  const _token = getAuthToken();
  useEffect(() => {
    if (_token) {
      dispatch(saveUserLoginState(true));
      dispatch(actionUserDetails());
    } else {
      dispatch(saveUserLoginState(false));
    }
  }, [_token]);

  return (
    <main className="mainFluidApp">
      <div className="fluid">
        {ComponetSwitch(curPage)}
        <Toaster
          position="top-right"
          reverseOrder={false}
          containerStyle={{
            top: 50,
            position: 'absolute',
          }}
        />
      </div>
    </main>
  );
}

export default App;
