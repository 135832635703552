import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { actionWalletAddressList } from '../../redux/action/CommonAction';
import { addrListInterface } from '../../../typing/interface';
import toast from 'react-hot-toast';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';

interface WalletDropProps {
  setWalletDetails: (data: addrListInterface) => void;
}
export const WalletDropDown: React.FC<WalletDropProps> = ({
  setWalletDetails,
}) => {
  const dispatch = useAppDispatch();
  const { walletAddressList, walletAddressListError } = useAppSelector(
    (state) => state.common,
  );
  const [openList, setOpenList] = useState(false);
  const [selectedAddress, setSelectedAddress] =
    useState<addrListInterface | null>(null);

  useEffect(() => {
    dispatch(actionWalletAddressList('approved'));
    if (walletAddressListError) {
      toast.error('Not able to get wallet address');
    }
  }, [dispatch]);

  const selectAddressHandler = (address: addrListInterface) => {
    setSelectedAddress(address);
    setWalletDetails(address);
    setOpenList(false);
  };

  return (
    <div
      className="bg-white rounded-lg p-2 w-full border border-gray-200 relative"
      onClick={() => setOpenList(!openList)}
    >
      {selectedAddress ? (
        <p>{selectedAddress.address}</p>
      ) : (
        <p className="text-gray-300">Select Address</p>
      )}

      {openList && (
        <div className="absolute left-0 top-[44px] h-[6rem] w-full bg-white overflow-scroll rounded-md custom-shadow">
          {walletAddressList.length ? (
            <>
              {walletAddressList?.map((address) => (
                <p
                  className="p-2 border-b-2 border-gray-200"
                  key={address.id}
                  onClick={() => selectAddressHandler(address)}
                >
                  {address.address}
                </p>
              ))}
            </>
          ) : (
            <p
              className="p-2 border-b-2 border-gray-200"
              onClick={() =>
                dispatch(savePageType(PageType.ADD_WALLET_ADDRESS))
              }
            >
              No Wallet Address Found click to add
            </p>
          )}
        </div>
      )}
    </div>
  );
};
