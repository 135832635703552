import React, { useState } from 'react';
import { postLogin } from '../../../utils/api';
import ReactImage from '../../../components/ReactImage/ReactImage';
import { assetObject } from '../../../utils/assetFileName';
import { FluidInput } from '../../../components/FluidInput/FluidInput';
import { FluidButton } from '../../../components/FluidButton/FluidButton';

interface EnterEmailProps {
  setOtpSent: React.Dispatch<React.SetStateAction<boolean>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

export const EnterEmail: React.FC<EnterEmailProps> = ({
  setOtpSent,
  setEmail,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const sendOtpHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setBtnLoading(true);
    const email = (event.target as HTMLFormElement).email.value;
    setEmail(email);
    try {
      const response = await postLogin({ email });
      setOtpSent(true);
    } catch (error) {
      console.log(error);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <section>
      <div className="flex justify-center">
        <ReactImage src={assetObject.emailSvg} alt="email-img" />
      </div>
      <p className="text-sm font-semibold pt-3">
        Enter your email address to receive One Time Password (OTP)
      </p>
      <form onSubmit={sendOtpHandler}>
        <div className="py-4">
          <FluidInput
            inputName="email"
            label="Enter your email id"
            type="email"
            required
          />
        </div>
        <div className="pt-2">
          <FluidButton
            buttonType="submit"
            variant="contained"
            color="primary"
            loading={btnLoading}
          >
            Continue
          </FluidButton>
        </div>
      </form>
    </section>
  );
};
