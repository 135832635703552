import React, { MouseEvent, useState } from 'react';
import { SvgIcon } from '../../components/ReactImage/SvgIcon';
import { assetObject } from '../../utils/assetFileName';
import FluidModal from '../../components/FluidModal/FluidModal';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import { FluidInput } from '../../components/FluidInput/FluidInput';
import { useAppDispatch } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';

export const AddAddressModal = () => {
  const [addAddressModal, setAddAddressModal] = useState(false);
  const dispatch = useAppDispatch();

  const handleModalClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <>
      <div className="flex justify-between p-2 pb-4">
        <p className="font-bold">Add New Address</p>
        <button
          onClick={() => dispatch(savePageType(PageType.ADD_WALLET_ADDRESS))}
        >
          <SvgIcon svgString={assetObject.addIcon} />
        </button>
      </div>
      <FluidModal
        modalStatus={addAddressModal}
        onModalClose={() => setAddAddressModal(false)}
      >
        <div className="flex flex-col" onClick={handleModalClick}>
          <div className="p-2">
            <div className="pb-2">
              <p className="text-xs pl-1 pb-2">Asset</p>
              <select
                className="bg-white rounded-md p-2 w-full border border-gray-200 relative"
                name="asset"
                id="asset"
              >
                <option key={'USDT'} value={'USDT'}>
                  {'USDT'}
                </option>
                <option key={'BTC'} value={'BTC'}>
                  {'BTC'}
                </option>
              </select>
            </div>
            <div className="pb-2">
              <p className="text-xs pl-1 pb-2">Network</p>
              <select
                className="bg-white rounded-md p-2 w-full border border-gray-200"
                name="asset"
                id="asset"
              >
                <option key={'TRON'} value={'TRON'}>
                  {'TRON'}
                </option>
                <option key={'TRX'} value={'TRX'}>
                  {'TRX'}
                </option>
              </select>
            </div>
            <div className="pb-2">
              <FluidInput inputName="address" label="Address" />
            </div>
            <div className="pb-2">
              <FluidInput inputName="platform" label="Platform" />
            </div>
            <div className="pb-2">
              <FluidInput inputName="platform" label="Address Label" />
            </div>
          </div>
          <div className="flex gap-2 p-2">
            <FluidButton
              variant="outlined"
              onClickHandler={() => setAddAddressModal(false)}
            >
              Cancel
            </FluidButton>
            <FluidButton variant="contained">Submit</FluidButton>
          </div>
        </div>
      </FluidModal>
    </>
  );
};
