import React from 'react';
import { PurchaseCrpyto } from './chunks/PurchaseCrpyto';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';

const PurchaseLayout = () => {
  return (
    <main className="p-4">
      <FluidHeader />
      <PurchaseCrpyto />
    </main>
  );
};

export default PurchaseLayout;
