import React, { ChangeEvent, useEffect, useState } from 'react';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import ReactImage from '../../components/ReactImage/ReactImage';
import { assetObject } from '../../utils/assetFileName';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import { getBankList, getUpiList } from '../../utils/api';
import AppPaymentPlaceholder from './AppPaymentPlaceholder';
import FluidLoader from '../../components/loader/FluidLoader';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  savePageType,
  saveSelectedBank,
  saveSelectedUpi,
} from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';
import { BankItemInterface, UpiItemInterface } from '../../../typing/interface';

const SelectPayModeLayout = () => {
  const [upiList, setUpiList] = useState<UpiItemInterface[]>([]);
  const [bankList, setBankList] = useState<BankItemInterface[]>([]);

  const [selectedPay, setSelectedPay] = useState('');
  const { userPlacedOrder } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();

  const [loader, setLoader] = useState(true);

  const getPaymentOption = async () => {
    try {
      const upiList = await getUpiList();
      const bankList = await getBankList();

      if (upiList?.results && upiList?.results.length)
        setUpiList(upiList?.results);

      if (bankList?.results && bankList?.results.length)
        setBankList(bankList?.results);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const selectPaymentMode = (
    event: ChangeEvent<HTMLInputElement>,
    type: string,
    data: UpiItemInterface | BankItemInterface,
  ) => {
    if (type === 'bank') {
      dispatch(saveSelectedBank(data));
      dispatch(saveSelectedUpi(null));
      setSelectedPay(type);
    }
    if (type === 'upi') {
      dispatch(saveSelectedUpi(data));
      dispatch(saveSelectedBank(null));
      setSelectedPay(type);
    }
  };

  const customHeader = (
    <FluidHeader
      title="Payment Method"
      onClickHandler={() => dispatch(savePageType(PageType.ORDER_DETAILS))}
    />
  );

  const continueHandler = () => {
    if (selectedPay === 'bank') {
      dispatch(savePageType(PageType.ADD_UTR_BANK));
    } else {
      dispatch(savePageType(PageType.ADD_UTR_UPI));
    }
  };

  useEffect(() => {
    getPaymentOption();
  }, []);

  if (loader) {
    return (
      <>
        {customHeader}
        <div className="flex justify-center mt-32 w-full">
          <FluidLoader width="4rem" />
        </div>
      </>
    );
  }

  if (!upiList.length && !bankList.length) {
    return (
      <>
        {customHeader}
        <AppPaymentPlaceholder />
      </>
    );
  }

  return (
    <>
      {customHeader}
      <section className="relative min-h-[20rem] max-h-full">
        <div className="p-4">
          <div className="flex justify-between">
            <p className="font-semibold">Select a payment method</p>
            <p className="color-primary font-bold text-lg">
              ₹ {userPlacedOrder?.fiat_amt}
            </p>
          </div>
          {bankList.length !== 0 && (
            <div className="border border-gray-300 rounded-lg p-2 my-2">
              <p className="font-semibold pb-1">Bank Account</p>
              {bankList.map((bank) => (
                <div className="flex flex-col gap-1" key={bank.id}>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      name="pay"
                      id={bank.acc_no}
                      required
                      onChange={(event) =>
                        selectPaymentMode(event, 'bank', bank)
                      }
                    />
                    <label htmlFor={bank.acc_no}>{bank.bank_name}</label>
                  </div>
                </div>
              ))}
            </div>
          )}
          {upiList.length !== 0 && (
            <div className="border border-gray-300 rounded-lg p-2 my-2">
              <p className="font-semibold pb-1">UPI Payment Options</p>
              <div className="flex flex-col gap-1">
                {upiList?.map((upi) => (
                  <div className="flex gap-2" key={upi.id}>
                    <input
                      type="radio"
                      name="pay"
                      id={upi.upi_id}
                      value={upi.upi_id}
                      required
                      onChange={(event) => selectPaymentMode(event, 'upi', upi)}
                    />
                    <label htmlFor={upi.upi_id}>{upi.upi_id}</label>
                    <ReactImage
                      src={assetObject.upiIconSmall}
                      alt="u"
                      height={2}
                      width={20}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="bg-[var(--light-green)] p-4 absolute w-full bottom-0">
          <FluidButton
            disabled={selectedPay === ''}
            onClickHandler={continueHandler}
          >
            Continue
          </FluidButton>
        </div>
      </section>
    </>
  );
};

export default SelectPayModeLayout;
