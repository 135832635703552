import React from 'react';
import styles from './styles.module.scss';

interface LoaderProps {
  width?: string;
}

const FluidLoader: React.FC<LoaderProps> = ({ width = '2rem' }) => {
  return (
    <span className={styles.svgBox}>
      <svg viewBox="25 25 50 50" style={{ width }}>
        <circle r="20" cy="50" cx="50"></circle>
      </svg>
    </span>
  );
};

export default FluidLoader;
