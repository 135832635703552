import React, { useEffect, useState } from 'react';
import styles from './sideSlider.module.scss';
import ReactImage from '../ReactImage/ReactImage';
import { SvgIcon } from '../ReactImage/SvgIcon';
import { assetObject } from '../../utils/assetFileName';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';
import useAuthHook from '../../hooks/authHook';
import { FluidButton } from '../FluidButton/FluidButton';
import { actionWalletAddressList } from '../../redux/action/CommonAction';
import toast from 'react-hot-toast';

interface SliderProps {
  isOpen?: boolean;
  setIsOpen: (open: boolean) => void;
}

const SideSlider: React.FC<SliderProps> = ({ isOpen = false, setIsOpen }) => {
  const { isUserLogedIn } = useAppSelector((state) => state.auth);
  const toggleSlider = () => {
    setIsOpen(!isOpen);
  };
  const dispatch = useAppDispatch();

  return (
    <div className={isOpen ? styles.sliderBox : ''}>
      <div
        className={`fixed top-0 left-0 h-full z-10 w-80 bg-white shadow-lg transform ${
          isOpen
            ? 'translate-x-0 transition-transform duration-300'
            : '-translate-x-full'
        }`}
      >
        <div className="px-2 pt-2">
          <button className="text-black" onClick={toggleSlider}>
            <svg
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
        {/* <div className="text-sm absolute bottom-0 left-1/2">
          Powered by <span className="text-[--carret-purple]">Carret</span> ✨
        </div> */}
        {isUserLogedIn ? (
          <UserLogedIn isOpen={isOpen} setIsOpen={setIsOpen} />
        ) : (
          <div className="p-4">
            {/* <div className="flex gap-2 items-center bg-[--primary-green] rounded-lg p-2"></div> */}
            <FluidButton
              onClickHandler={() => dispatch(savePageType(PageType.LOGIN_PAGE))}
            >
              Login
            </FluidButton>
          </div>
        )}
      </div>
    </div>
  );
};

const UserLogedIn: React.FC<SliderProps> = ({ isOpen, setIsOpen }) => {
  const dispatch = useAppDispatch();
  const { userInfo, isUserLogedIn } = useAppSelector((state) => state.auth);
  const { walletAddressList } = useAppSelector((state) => state.common);

  const additionalInfoCheck = () => {
    if (!userInfo?.annual_income || !userInfo.occupation) {
      return { pending: true, message: 'Pending' };
    } else if (userInfo?.phone_verification_status === 'verified') {
      return { pending: false, message: '' };
    } else if (userInfo?.phone_verification_status === 'in_progress') {
      return { pending: true, message: 'InReview' };
    } else if (userInfo?.phone_verification_status === 'pending') {
      return { pending: true, message: 'Pending' };
    } else {
      return { pending: false, message: '' };
    }
  };

  const addBankHandler = () => {
    if (!userInfo?.kyc_verified) {
      toast(() => 'Please complete your kyc to proceed.');
    } else if (userInfo?.kyc_status === 'rejected') {
      toast.error('Your KYC has been rejected');
      return;
    } else if (userInfo?.kyc_status === 'manual_review') {
      toast(() => 'Your KYC is under review.');
      return;
    } else if (userInfo?.kyc_status === 'failed') {
      toast(() => 'Your KYC verification failed please try again.');
      return;
    } else {
      dispatch(savePageType(PageType.ADD_PAYMENT_METHOD));
    }
  };

  const { logoutUser } = useAuthHook();

  const logoutHandler = () => {
    setIsOpen(false);
    logoutUser();
  };

  useEffect(() => {
    if (!walletAddressList) dispatch(actionWalletAddressList(''));
  }, [isUserLogedIn, walletAddressList]);

  return (
    <div className="px-4">
      <div className="flex gap-2 items-center bg-[--primary-green] rounded-lg p-2">
        <div>
          <ReactImage
            alt="avatar"
            src="https://carret-media.s3.ap-south-1.amazonaws.com/avatar-images/44.png"
            className="rounded-full"
            height={60}
            width={60}
          />
        </div>
        <div>
          <p className="text-white font-semibold text-lg">
            {userInfo?.user?.first_name} {userInfo?.user?.last_name}
          </p>
          <p className="text-white text-sm">{userInfo?.user?.email}</p>
        </div>
      </div>
      <div className="p-2">
        {!userInfo?.kyc_verified && (
          <div
            className="flex items-center gap-2 border-b-2 py-4 px-1 cursor-pointer"
            onClick={() => dispatch(savePageType(PageType.KYC_PAGE))}
          >
            <SvgIcon svgString={assetObject.kycIcon} />
            <span>KYC</span>
            <p className="text-orange-300 text-sm capitalize">
              ({userInfo?.kyc_status})
            </p>
          </div>
        )}
        {additionalInfoCheck().pending && (
          <div
            className="flex items-center gap-2 border-b-2 py-4 px-1 cursor-pointer"
            onClick={() =>
              userInfo?.phone_verification_status === 'in_progress'
                ? toast(() => 'Your Mobile number is under verfication.')
                : dispatch(savePageType(PageType.ADDITIONAL_INFO))
            }
          >
            <SvgIcon svgString={assetObject.additionalInfoIcon} />
            <span>Additional Information</span>
            <p className="text-orange-300 text-sm">
              ({additionalInfoCheck().message})
            </p>
          </div>
        )}

        <div
          className="flex items-center gap-2 border-b-2 py-4 px-1 cursor-pointer"
          onClick={addBankHandler}
        >
          <SvgIcon svgString={assetObject.bankIcon} />
          <span>Payment Method</span>
          {!userInfo?.is_bank_added && (
            <p className="text-orange-300 text-sm">(Pending)</p>
          )}
        </div>
        <div
          className="flex items-center gap-2 border-b-2 py-4 px-1 cursor-pointer"
          onClick={() => dispatch(savePageType(PageType.ADDRESS_LIST))}
        >
          <SvgIcon svgString={assetObject.walletSvgSmall} />
          <span>Wallet Address</span>
          {!walletAddressList ? (
            <p className="text-orange-300 text-sm">(Pending)</p>
          ) : (
            <></>
          )}
        </div>
        <div
          className="flex items-center gap-2 border-b-2 py-4 px-1 cursor-pointer"
          onClick={() => dispatch(savePageType(PageType.ORDER_HISTORY))}
        >
          <SvgIcon svgString={assetObject.historyIconSmall} />
          <span>Order History</span>
        </div>
        <div
          className="flex items-center gap-2 border-b-2 py-4 px-1 cursor-pointer"
          onClick={() => dispatch(savePageType(PageType.TRANSACTION_HISTORY))}
        >
          <SvgIcon svgString={assetObject.historyIconSmall} />
          <span>Transaction History</span>
        </div>
        <div
          className="flex items-center gap-2 border-b-2 py-4 px-1 cursor-pointer"
          onClick={() => dispatch(savePageType(PageType.SUPPORT))}
        >
          <SvgIcon svgString={assetObject.supportIcon} />
          <span>Support</span>
        </div>
        <div
          className="flex items-center gap-2 border-b-2 py-4 px-1 cursor-pointer"
          onClick={() => dispatch(savePageType(PageType.DELETE_ACCOUNT))}
        >
          <SvgIcon svgString={assetObject.deleteIcon} />
          <span>Delete Account</span>
        </div>
        <div
          className="flex items-center gap-2 border-b-2 py-4 px-1 cursor-pointer"
          onClick={logoutHandler}
        >
          <SvgIcon svgString={assetObject.logoutIcon} />
          <span className="text-red-400">Logout</span>
        </div>
      </div>
    </div>
  );
};

export default SideSlider;
