import React from 'react';
import { SupportList } from './Chunks/SupportList';

const SupportLayout = () => {
  return (
    <main className="p-4">
      <SupportList />
    </main>
  );
};

export default SupportLayout;
