import { useEffect } from 'react';

const useScript = (
  url: string,
  async = true,
  crossOrigin: 'anonymous' | 'use-credentials' = 'anonymous',
) => {
  useEffect(() => {
    // Check if the script already exists
    const existingScript = document.querySelector(`script[src="${url}"]`);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = url;
      script.async = async;
      script.crossOrigin = crossOrigin;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }

    // If the script already exists, do nothing
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [url, async, crossOrigin]);
};

export default useScript;
