import React, { useState } from 'react';
import ReactImage from '../../../components/ReactImage/ReactImage';
import { assetObject } from '../../../utils/assetFileName';
import { FluidOtp } from '../../../components/FluidOtp/FluidOtp';
import { FluidButton } from '../../../components/FluidButton/FluidButton';
import useAuthHook from '../../../hooks/authHook';

interface EnterOptProps {
  email: string;
}
export const EnterOtp: React.FC<EnterOptProps> = ({ email }) => {
  const [otp, setOtp] = useState('');

  const { btnLoading, verifyOtp } = useAuthHook();

  return (
    <section>
      <div className="flex justify-center">
        <ReactImage src={assetObject.otpSvg} alt="otp-img" />
      </div>
      <p className="text-sm font-semibold pt-3">
        Enter the One Time Password (OTP) receive on your email
      </p>
      <div className="py-4">
        <FluidOtp otp={otp} setOtp={setOtp} />
      </div>
      <div className="pt-2">
        <FluidButton
          variant="contained"
          color="primary"
          disabled={otp.length < 6}
          onClickHandler={() => verifyOtp(email, otp)}
          loading={btnLoading}
        >
          Submit
        </FluidButton>
      </div>
    </section>
  );
};
