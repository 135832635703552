import React, { useEffect, useState } from 'react';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { TransactionHistoryInterface } from '../../../typing/interface';
import moment from 'moment';
import { actionTransectionHistory } from '../../redux/action/CommonAction';
import FluidModal from '../../components/FluidModal/FluidModal';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';

const TransactionHistoryLayout = () => {
  const { transactionHistory } = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();
  const [selectedOrder, setSelectedOrder] =
    useState<TransactionHistoryInterface | null>(null);

  const [openModal, setOpenModal] = useState(false);

  const openDetailsHandler = (order: TransactionHistoryInterface) => {
    setSelectedOrder(order);
    setOpenModal(true);
  };
  const onCloseDetails = () => {
    setSelectedOrder(null);
    setOpenModal(false);
  };

  useEffect(() => {
    dispatch(actionTransectionHistory());
  }, []);

  return (
    <section className="p-4">
      <FluidHeader
        title="Transaction History"
        onClickHandler={() => dispatch(savePageType(PageType.HOME_PAGE))}
      />
      {transactionHistory?.map((data) => (
        <TransactionCard
          key={data.id}
          transactionHistory={data}
          openDetailsHandler={openDetailsHandler}
        />
      ))}
      <FluidModal
        modalStatus={openModal}
        cancelIconEnable
        onModalClose={onCloseDetails}
      >
        {/* <OrderDetails selectedOrder={selectedOrder} /> */}
        <>p</>
      </FluidModal>
    </section>
  );
};

export default TransactionHistoryLayout;

interface TransectionProp {
  transactionHistory: TransactionHistoryInterface;
  openDetailsHandler: (order: TransactionHistoryInterface) => void;
}

const TransactionCard: React.FC<TransectionProp> = ({
  transactionHistory,
  openDetailsHandler,
}) => {
  const statusColorSwitch = (status: string) => {
    switch (status) {
      case 'success':
        return '--primary-green';
      case 'failed':
        return '--error-red';
      case 'pending':
        return '--pending-yellow';
      default:
        break;
    }
  };
  const status = 'success';
  return (
    <div
      className="flex p-2 border-b-2 border-gray-200 items-center gap-2"
      onClick={() => openDetailsHandler(transactionHistory)}
    >
      <div
        className={`bg-[${statusColorSwitch(status)}] p-4 rounded-full h-fit`}
      >
        <div className="bg-white p-[1px]"></div>
      </div>
      <div className="flex justify-between w-full">
        <div>
          <div className="flex gap-2 items-center">
            <p
              className={`font-semibold text-lg text-[var(${statusColorSwitch(status)})]`}
            >
              {/* {transactionHistory.side}  */}
            </p>
            <p className="text-xs">{transactionHistory.status}</p>
          </div>
          <p className="text-xs">
            {' '}
            {moment(transactionHistory.created_at).format(
              'DD MMMM YYYY, HH:mm',
            )}
          </p>
        </div>
        <div>
          <p
            className={`font-semibold text-[var(${statusColorSwitch(status)})] text-right`}
          >
            USDT
          </p>
          <p className={`text-[var(${statusColorSwitch(status)})]`}>
            {/* {transactionHistory.filled_quantity_on_lp} */}
          </p>
        </div>
      </div>
    </div>
  );
};
