import React, { FormEvent, useEffect, useState } from 'react';
import { FluidInput } from '../../../components/FluidInput/FluidInput';
import { FluidButton } from '../../../components/FluidButton/FluidButton';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  actionAssetList,
  actionFiatList,
} from '../../../redux/action/CommonAction';
import ReactImage from '../../../components/ReactImage/ReactImage';
import { assetObject } from '../../../utils/assetFileName';
import { MenuDrawer } from '../../../components/MenuDrawer/MenuDrawer';
import CurrencyList from '../../../components/CurrencyList/CurrencyList';
import AssetList from '../../../components/AssetList/AssetList';
import { missingDepositClaimApi } from '../../../utils/api';
import { WalletDropDown } from '../../../components/WalletDropDown/WalletDropDown';
import { WalletAddressListInterface } from '../../../../typing/interface';
import toast from 'react-hot-toast';

export const MissingDepositForm = () => {
  const dispatch = useAppDispatch();
  const { fiatList, assetList, selectedFiat, selectedAsset } = useAppSelector(
    (state) => state.common,
  );

  const [fiatSelectorStatus, setFiatSelectorStatus] = useState(false);
  const [assetSelectorStatus, setAssetSelectorStatus] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [walletDetails, setWalletDetails] =
    useState<WalletAddressListInterface | null>();

  const fiatSelectorHandler = () => {
    setFiatSelectorStatus(true);
  };

  const assetSelectorHandler = () => {
    setAssetSelectorStatus(true);
  };

  const formSubmitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // if (!walletDetails || !selectedAsset) {
    //   toast.error('Please Try Again latter!');
    //   return;
    // }
    const amount = (event.target as HTMLFormElement).amount.value;
    const utr_no = (event.target as HTMLFormElement).utr_no.value;
    const fiat_ccy: string | undefined = selectedFiat?.symbol;
    const network: string | undefined = selectedAsset?.network;
    const asset: number | undefined = selectedAsset?.id;
    const wallet: number | undefined = walletDetails?.id;

    if (!fiat_ccy || !asset || !wallet || !network) {
      toast.error('Please Try Again latter!');
      return;
    }
    try {
      const res = await missingDepositClaimApi({
        amount,
        utr_no,
        fiat_ccy,
        asset,
        wallet,
        network,
      });
      toast.success('Form Submited');
    } catch (error) {
      toast.error('Please Try Again latter!');
    } finally {
      setResetForm(!resetForm);
    }
  };

  useEffect(() => {
    dispatch(actionFiatList());
    dispatch(actionAssetList({ onChainActive: false }));
  }, []);

  return (
    <>
      <form onSubmit={formSubmitHandler} key={resetForm ? '1' : '2'}>
        <div className="bg-[var(--light-green)] p-2 py-3 rounded-xl flex flex-col gap-3">
          <div className="relative">
            <FluidInput
              inputName="amount"
              label="Amount"
              type="number"
              required
            />
            <p className="text-xs italic text-gray-600 pt-1">
              Enter the INR amount you have succesfully transferred.
            </p>
            <button
              onClick={fiatSelectorHandler}
              type="button"
              className="p-1.5 m-1 mr-2 rounded-lg bg-gray-100 absolute bottom-0 right-0 flex items-center gap-1 mb-6"
            >
              {/* <span className="text-sm font-semibold">$</span> */}
              <span className="text-sm font-semibold font-normal">
                {selectedFiat?.symbol}
              </span>
              <span>
                {' '}
                <ReactImage
                  src={assetObject.dropDownArrow}
                  alt="dropDownArrow icon"
                  height={10}
                  width={10}
                />
              </span>
            </button>
          </div>
          <div>
            <FluidInput inputName="utr_no" label="UTR No." required />
            <p className="text-xs italic text-gray-600 pt-1">
              Enter the UTR number of the successful deposit.
            </p>
          </div>
          <div className="relative" onClick={assetSelectorHandler}>
            {/* <FluidInput inputName="asset" label="Select asset" /> */}
            <div className="bg-white rounded-lg p-2 w-full border border-gray-200 relative">
              <p className="text-gray-300">Select Network</p>
            </div>
            <p className="text-xs italic text-gray-600 pt-1">
              Select the Asset and the Network for which the INR deposit has
              been made.
            </p>
            <button
              className="p-1 m-1 mr-2 rounded-lg bg-gray-100 absolute top-0 right-0 flex items-center gap-1 mt-1"
              type="button"
            >
              {selectedAsset?.img_url && (
                <ReactImage
                  src={selectedAsset?.img_url}
                  alt="dropDownArrow icon"
                  height={20}
                  width={20}
                />
              )}
              <span className="text-sm font-semibold">
                <p className="font-normal leading-tight">
                  {selectedAsset?.symbol}
                </p>
                <p className="text-[9px] font-light leading-none">
                  {selectedAsset?.network}
                </p>
              </span>
              <span>
                {' '}
                <ReactImage
                  src={assetObject.dropDownArrow}
                  alt="dropDownArrow icon"
                  height={10}
                  width={10}
                />
              </span>
            </button>
          </div>
          <div>
            {/* <FluidInput
              inputName="wallet_address"
              label="Wallet ID"
              onChange={function (value: string): void {
                console.log(value);
              }}
            /> */}
            <WalletDropDown setWalletDetails={setWalletDetails} />
            <p className="text-xs italic text-gray-600 pt-1">
              Enter the Wallet Address where the asset will be received. Make
              sure the address is of the correct Network.
            </p>
          </div>
        </div>
        <div className="py-4">
          <FluidButton buttonType="submit">Claim Amount</FluidButton>
        </div>
      </form>
      {fiatSelectorStatus && (
        <MenuDrawer
          drawerState={true}
          onDrawerClose={() => setFiatSelectorStatus(false)}
        >
          <CurrencyList fiatList={fiatList} />
        </MenuDrawer>
      )}
      {assetSelectorStatus && (
        <MenuDrawer
          drawerState={true}
          onDrawerClose={() => setAssetSelectorStatus(false)}
        >
          <AssetList assetList={assetList} />
        </MenuDrawer>
      )}
    </>
  );
};
