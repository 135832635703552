import React, { ReactNode, useEffect, useState } from 'react';
import styles from './menuDrawer.module.scss';

interface MenuDrawerProps {
  children: ReactNode;
  drawerState: boolean;
  onDrawerClose?: () => void;
}

export const MenuDrawer: React.FC<MenuDrawerProps> = ({
  children,
  drawerState = false,
  onDrawerClose,
}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const outBoxClickHandler = () => {
    setShowDrawer(!showDrawer);
    setTimeout(() => {
      if (onDrawerClose) onDrawerClose();
    }, 777);
  };

  useEffect(() => {
    setShowDrawer(drawerState);
  }, [drawerState]);
  return (
    <div className={styles.menuDrawerBox} onClick={outBoxClickHandler}>
      <div className={styles.boxInside}>
        <div
          className={`transition-all w-full duration-500 ease-in-out bg-white absolute pb-4 ${
            showDrawer ? 'bottom-0' : 'bottom-[-400px]'
          } rounded-t-xl`}
        >
          <div className="w-100 flex justify-center p-1">
            <span className="border-2 border-gray-300 rounded w-[40px]" />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
