import React, { FormEvent, useEffect, useState } from 'react';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import FluidModal from '../../components/FluidModal/FluidModal';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { updateUserProfileApi } from '../../utils/api';
import { StatusModal } from '../../components/StatusModal/StatusModal';
import { isValidMobileNumber, stopPropagationHandlerDiv } from '../../utils';
import { PageType } from '../../utils/constants';
import { FluidInput } from '../../components/FluidInput/FluidInput';
import { actionUserDetails } from '../../redux/action/AuthAction';

interface IsProfileProp {
  status: 'error' | 'success';
  text: string;
}
const ancIncome = [
  {
    ancIncome: '< ₹5 Lakhs',
  },
  {
    ancIncome: '₹5 Lakhs-₹10 Lakhs',
  },
  {
    ancIncome: '₹10 Lakhs-₹25 Lakhs',
  },
  {
    ancIncome: '₹25 Lakhs-₹50 Lakhs',
  },
  {
    ancIncome: '₹50Lakhs-1 Crore',
  },
  {
    ancIncome: '>₹1 Crore',
  },
];

const ocpp = [
  {
    ocppType: 'Private Job',
  },
  {
    ocppType: 'Goverment Job',
  },
  {
    ocppType: 'Business Owner',
  },
  {
    ocppType: 'Home Maker',
  },

  {
    ocppType: 'Freelancer',
  },
  {
    ocppType: 'Unemployed',
  },
  {
    ocppType: 'Student',
  },
  {
    ocppType: 'Professional',
  },
];

const AdditionalInfoLayout = () => {
  const [pepModal, setPepModal] = useState(false);
  const [isPep, setIsPep] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);

  const [mobileNumberErr, setMobileNumberErr] = useState({
    isMobileErr: false,
    helperText: 'Please enter correct mobile no',
  });

  const [isProfileUpdate, setIsProfileUpdate] = useState<IsProfileProp>({
    status: 'success',
    text: 'Your profile has been updated',
  });

  const dispatch = useAppDispatch();
  const { lastPage } = useAppSelector((state) => state.common);
  const { userInfo } = useAppSelector((state) => state.auth);

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!userInfo) return;

    const ancIncome = (event.target as HTMLFormElement).ancIncome.value;
    const ocpp = (event.target as HTMLFormElement).ocpp.value;
    const phoneNumber = (event.target as HTMLFormElement).phone.value;

    if (isValidMobileNumber(phoneNumber)) {
      console.log('valid');
      setMobileNumberErr((pre) => {
        return { ...pre, isMobileErr: false };
      });
    } else {
      setMobileNumberErr((pre) => {
        console.log('invalid');
        return { ...pre, isMobileErr: true };
      });
      return;
    }

    try {
      const response = await updateUserProfileApi(
        {
          is_politically_exposed: isPep,
          occupation: ocpp,
          annual_income: ancIncome,
          phone_number: phoneNumber.replace(/[^\d]/g, ''),
        },
        userInfo.id,
      );
      setOpenModalStatus(true);
      setIsProfileUpdate({
        status: 'success',
        text: 'Your profile has been updated',
      });
    } catch (error) {
      console.log(error);
      setIsProfileUpdate({
        status: 'error',
        text: 'Something went wrong please try again later!',
      });
    }
  };

  const modalCloseHandler = () => {
    dispatch(savePageType(PageType.HOME_PAGE));
    dispatch(actionUserDetails());
  };

  useEffect(() => {
    dispatch(actionUserDetails());
  }, []);
  return (
    <section className="p-4">
      <FluidHeader
        title="Additional Info"
        onClickHandler={() => dispatch(savePageType(lastPage))}
      />
      <form onSubmit={submitHandler}>
        <div className="bg-[var(--light-green)] p-2 rounded-xl">
          <p className="text-lg font-semibold pb-4">
            Almost there, this is the final step
          </p>
          <div>
            <p className="text-xs pl-1 pb-2">Annual Income</p>
            <select
              className="bg-white rounded-md p-2 w-full border border-gray-200"
              name="ancIncome"
              id="ancIncome"
              disabled={userInfo?.annual_income ? true : false}
            >
              {userInfo?.annual_income ? (
                <option
                  key={userInfo?.annual_income}
                  value={userInfo?.annual_income}
                >
                  {userInfo?.annual_income}
                </option>
              ) : (
                <>
                  {' '}
                  {ancIncome.map((item) => (
                    <option key={item.ancIncome} value={item.ancIncome}>
                      {item.ancIncome}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
          <div className="pt-4">
            <p className="text-xs pl-1 pb-2">Occupation</p>
            <select
              className="bg-white rounded-md p-2 w-full border border-gray-200"
              name="ocpp"
              id="ocpp"
              disabled={userInfo?.occupation ? true : false}
            >
              {userInfo?.occupation ? (
                <option key={userInfo?.occupation} value={userInfo?.occupation}>
                  {userInfo?.occupation}
                </option>
              ) : (
                <>
                  {ocpp.map((item) => (
                    <option key={item.ocppType} value={item.ocppType}>
                      {item.ocppType}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
          <div className="pt-4">
            <FluidInput
              inputName="phone"
              type="tel"
              label="Mobile No."
              placeHolder="Ex - 8824661239"
              required
              inputError={mobileNumberErr.isMobileErr}
              helperText={mobileNumberErr.helperText}
            />
          </div>
        </div>
        <div className="p-2 pt-4" onClick={stopPropagationHandlerDiv}>
          <p className="text-sm">
            Are you Politically Exposed Person (PEP) or related to one
          </p>
          <div className="flex gap-2 pt-4">
            <FluidButton
              variant={isPep ? 'outlined' : 'contained'}
              color="primary"
              buttonType="button"
              onClickHandler={() => setIsPep(false)}
            >
              No
            </FluidButton>
            <FluidButton
              variant={isPep ? 'contained' : 'outlined'}
              onClickHandler={() => setPepModal(true)}
              buttonType="button"
            >
              Yes
            </FluidButton>
          </div>
        </div>
        <div
          className="flex items-start gap-2 text-xs pt-2 pl-2 mt-2"
          onClick={stopPropagationHandlerDiv}
        >
          <input type="checkbox" name="check" id="check" required />{' '}
          <label htmlFor="check">
            By checking this, I acknowledge that all the information I provided
            is true to the best of my knowledge.
          </label>
        </div>
        <div className="mt-4">
          <FluidButton variant="contained" buttonType="submit">
            Continue
          </FluidButton>
        </div>
      </form>
      <FluidModal
        modalStatus={pepModal}
        onModalClose={() => setPepModal(false)}
        cancelIconEnable
      >
        <div className="flex flex-col items-center gap-2 p-2">
          <p className="font-semibold">Politically Exposed Person (PEP)</p>
          <p className="text-center text-sm pb-1">
            Politically Exposed Persons&quot; (PEPs) are individuals who are or
            have been entrusted with prominent public functions by a foreign
            country, including the Heads of States/Governments, senior
            politicians, senior government or judicial or military officers,
            senior executives of state- owned corporations and important
            political party officials.
          </p>
          <FluidButton
            onClickHandler={() => {
              setIsPep(true);
              setPepModal(false);
            }}
          >
            Got it
          </FluidButton>
        </div>
      </FluidModal>
      <FluidModal
        modalStatus={openModalStatus}
        cancelIconEnable
        onModalClose={modalCloseHandler}
      >
        <StatusModal
          text={isProfileUpdate.text}
          statusType={isProfileUpdate.status}
        />
      </FluidModal>
    </section>
  );
};

export default AdditionalInfoLayout;
