import React, { useEffect, useState } from 'react';
import ReactImage from '../../components/ReactImage/ReactImage';
import { assetObject } from '../../utils/assetFileName';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import useScript from './useExternalScripts';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { getHpvToken, verifyKycApi } from '../../utils/api';
import toast from 'react-hot-toast';
import { PageType } from '../../utils/constants';
import FluidModal from '../../components/FluidModal/FluidModal';
import { StatusModal } from '../../components/StatusModal/StatusModal';
import { actionUserDetails } from '../../redux/action/AuthAction';

// Declare a custom type for the HyperKycConfig constructor
interface HyperKycConfig {
  new (accessToken: string, workflowId: string, transactionId: string): any;
}

// Extend the Window interface to include the HyperKycConfig constructor
interface CustomWindow extends Window {
  HyperKYCModule: any;
  HyperKycConfig: HyperKycConfig;
}

// const APP_ID = '715d0c';
// const APP_KEY = 'a88a84a4d3811e980f97';
const WORKFLOW_ID = 'Carret_workflow';

const KycLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const { lastPage, curPage } = useAppSelector((state) => state.common);
  const { userInfo } = useAppSelector((state) => state.auth);
  const [hpvToken, setHvpToken] = useState<string | null>(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusModalInfo, setStatusModalInfo] = useState({
    message: '',
    status: 'success',
  });
  const [kycStatus, setKycStatus] = useState('');

  function generateTransactionId(email: string) {
    const epochTime = Date.now(); // Get current timestamp in milliseconds
    const transactionId = `${email}_${epochTime}`; // Combine username and epoch time
    return transactionId;
  }

  const verifyKycHandler = async (
    transaction_id: string,
    application_status: string,
  ) => {
    try {
      const data = {
        transaction_id,
        application_status,
      };
      const response = await verifyKycApi(data);
    } catch (error) {
      console.log(error);
    }
  };

  const launchKyc = () => {
    console.log('launch kyc');
    if (!userInfo) return;

    const accessToken = hpvToken || '';
    const workflowId = WORKFLOW_ID;
    const transactionId = generateTransactionId(userInfo?.user.email);

    console.log({
      accessToken,
      workflowId,
      transactionId,
    });

    const hyperKycConfig = new (
      window as unknown as CustomWindow
    ).HyperKycConfig(accessToken, workflowId, transactionId);

    const handler = (HyperKycResult: { status: string }) => {
      console.log(HyperKycResult.status, 'HyperKycResult.status');

      setKycStatus(HyperKycResult.status);

      dispatch(actionUserDetails());

      switch (HyperKycResult.status) {
        // ----Incomplete workflow----
        case 'user_cancelled':
          // Insert code block 1
          break;
        case 'error':
          toast.error('Something went wrong');
          break;

        // ----Complete workflow-----
        case 'auto_approved':
          // Insert code block 3
          setShowStatusModal(true);
          setStatusModalInfo({
            message: 'You KYC is verified',
            status: 'success',
          });
          verifyKycHandler(transactionId, 'auto_approved');
          break;
        case 'auto_declined':
          // Insert code block 4
          setShowStatusModal(true);
          setStatusModalInfo({
            message: 'You KYC is rejected. contact fluid team',
            status: 'error',
          });
          verifyKycHandler(transactionId, 'auto_declined');
          break;
        case 'needs_review':
          // Insert code block 5
          setShowStatusModal(true);
          setStatusModalInfo({
            message: 'You KYC is under review',
            status: 'error',
          });
          verifyKycHandler(transactionId, 'needs_review');
          break;
      }
    };

    (window as unknown as CustomWindow).HyperKYCModule.launch(
      hyperKycConfig,
      handler,
    );
  };

  const statusModalCloseHandler = () => {
    if (kycStatus === 'auto_approved') {
      dispatch(savePageType(PageType.ADDITIONAL_INFO));
    } else {
      dispatch(savePageType(PageType.HOME_PAGE));
    }
  };

  const updateToken = async () => {
    try {
      const res = await getHpvToken();
      setHvpToken(res.result.token);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    updateToken();
  }, []);

  useScript(
    'https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/hyperverge-web-sdk@8.4.2/src/sdk.min.js',
  );

  return (
    <>
      <FluidHeader
        title="KYC"
        onClickHandler={() => dispatch(savePageType(PageType.HOME_PAGE))}
      />
      <section className="p-4">
        <div className="border border-gray-200 rounded-xl">
          <p className="text-lg font-semibold rounded-t-xl bg-[var(--light-green)] p-3">
            Things required to get your KYC done
          </p>
          <div className="p-2">
            <div className="flex items-center gap-4">
              <div className="bg-[var(--light-green)] p-4 rounded-full w-fit">
                <ReactImage
                  src={assetObject?.panKycDummy}
                  height={30}
                  width={30}
                  alt="pan icon"
                />
              </div>
              <div>
                <p className="font-semibold">PAN Card</p>
                <p>Scanned copy of your PAN card</p>
              </div>
            </div>
          </div>
          <div className="px-9">
            <ReactImage
              src={assetObject?.downDotedArrow}
              height={8}
              width={8}
              alt="downDotedArrow icon"
            />
          </div>
          <div className="p-2">
            <div className="flex items-center gap-4">
              <div className="bg-[var(--light-green)] p-4 rounded-full w-fit">
                <ReactImage
                  src={assetObject?.aadharKycDummy}
                  height={30}
                  width={30}
                  alt="aadhar icon"
                />
              </div>
              <div>
                <p className="font-semibold">Aadhar Card</p>
                <p>Scanned copy of front and back side of your aadhar card</p>
              </div>
            </div>
          </div>
          <div className="px-9">
            <ReactImage
              src={assetObject?.downDotedArrow}
              height={8}
              width={8}
              alt="downDotedArrow icon"
            />
          </div>
          <div className="p-2">
            <div className="flex items-center gap-4">
              <div className="bg-[var(--light-green)] p-4 rounded-full w-fit">
                <ReactImage
                  src={assetObject?.profileKycDummy}
                  height={30}
                  width={30}
                  alt="photo icon"
                />
              </div>
              <div>
                <p className="font-semibold">Photo</p>
                <p>You are required to take your selfie for the verification</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <FluidButton
            variant="contained"
            color="primary"
            onClickHandler={launchKyc}
            // loading={loading}
            disabled={!hpvToken}
          >
            Lets Start
          </FluidButton>
        </div>
        <FluidModal
          modalStatus={showStatusModal}
          onModalClose={statusModalCloseHandler}
        >
          <StatusModal
            statusType={statusModalInfo.status}
            text={statusModalInfo.message}
          />
        </FluidModal>
      </section>
    </>
  );
};

export default KycLayout;
