import React, { FC } from 'react';

interface ICustomImageProps {
  src: string;
  alt: string;
  className?: string;
  width?: number | string;
  height?: number | string;
}

const ReactImage: FC<ICustomImageProps> = ({
  src,
  alt,
  className,
  width,
  height,
}) => {
  // const [optimizedSrc, setOptimizedSrc] = useState('');

  // useEffect(() => {
  //   const optimizedImage = optimizeCloudinaryImage(src);
  //   setOptimizedSrc(optimizedImage);
  // }, [src]);

  return (
    <img
      src={src}
      alt={alt}
      className={className}
      width={width}
      height={height}
    />
  );
};

export default ReactImage;
