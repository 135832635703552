import React, { useEffect, useState } from 'react';
import { FluidInput } from '../../../components/FluidInput/FluidInput';
import { FluidButton } from '../../../components/FluidButton/FluidButton';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { getCalculatedFees } from '../../../utils/api';
import { errorReaderToast, roundNumber } from '../../../utils';
import {
  actionAssetList,
  actionAssetPriceUpdate,
  actionFiatList,
} from '../../../redux/action/CommonAction';
import ReactImage from '../../../components/ReactImage/ReactImage';
import { assetObject } from '../../../utils/assetFileName';
import { MenuDrawer } from '../../../components/MenuDrawer/MenuDrawer';
import CurrencyList from '../../../components/CurrencyList/CurrencyList';
import AssetList from '../../../components/AssetList/AssetList';
import {
  saveCurPriceTrigger,
  saveOrderFees,
  savePageType,
  saveUserPlacedOrderDetails,
} from '../../../redux/reducer/CommonReducer';
import { PageType } from '../../../utils/constants';
import useAuthHook from '../../../hooks/authHook';

export const PurchaseCrpyto = () => {
  const {
    fiatList,
    assetList,
    selectedFiat,
    selectedAsset,
    curAssetPrice,
    orderFees,
  } = useAppSelector((state) => state.common);
  const { isUserLogedIn, userInfo } = useAppSelector((state) => state.auth);
  const [fiatSelectorStatus, setFiatSelectorStatus] = useState(false);
  const [assetSelectorStatus, setAssetSelectorStatus] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const dispatch = useAppDispatch();
  const [fiatAmt, setFiatAmt] = useState<number | null>(null);
  const [cryptoAmt, setCryptoAmt] = useState<number | null>(null);
  const [fees, setFees] = useState<number>(0);
  const [costOfAsset, setCostOfAsset] = useState(0);

  const resetAllState = () => {
    setCryptoAmt(null);
    setFees(0);
    setBtnDisabled(true);
    dispatch(saveOrderFees({ network_fee: 0, carret_fee: 0 }));
  };
  const { userKycCheck } = useAuthHook();

  const feesHandler = async (fiatAmt: number) => {
    if (!selectedAsset || !curAssetPrice) return;
    try {
      const res = await getCalculatedFees({
        fiat_amt: fiatAmt,
        asset_amt: 1,
        asset: selectedAsset?.symbol,
        network: selectedAsset?.network,
        fiat_curr: 'INR',
      });

      dispatch(saveOrderFees(res));

      setFees(res.network_fee * curAssetPrice + res.carret_fee);
      return res.network_fee * curAssetPrice + res.carret_fee;
    } catch (error) {
      console.log(error);
      setBtnDisabled(true);
      errorReaderToast(error);
    }
  };

  const enterAmountHandler = async (value: string, type: string) => {
    const valueNum = Number(value);

    dispatch(saveCurPriceTrigger(true));

    if (value === '') {
      resetAllState();
      setFiatAmt(null);
    }

    if (value) {
      if (type === 'fiat') {
        setFiatAmt(valueNum);

        if (selectedAsset?.min_buy && curAssetPrice) {
          if (valueNum > selectedAsset?.min_buy) {
            await feesHandler(valueNum);
            setBtnDisabled(false);
          } else {
            setBtnDisabled(true);
            resetAllState();
          }
        }
      }
      // else {
      //   setCryptoAmt(valueNum);
      //   const fiatNum = valueNum * Number(curAssetPrice);
      //   setFiatAmt(fiatNum);
      //   if (selectedAsset?.min_buy && fiatNum > selectedAsset?.min_buy) {
      //     setBtnDisabled(false);
      //     feesHandler(valueNum, fiatNum);
      //   } else {
      //     setBtnDisabled(true);
      //   }
      // }
    }
  };

  const fiatSelectorHandler = () => {
    setFiatSelectorStatus(true);
  };

  const assetSelectorHandler = () => {
    setAssetSelectorStatus(true);
  };

  const ProceedHandler = () => {
    if (btnDisabled) return;
    if (userKycCheck()) return;

    dispatch(
      saveUserPlacedOrderDetails({
        fiat_amt: fiatAmt,
        asset_amt: roundNumber(cryptoAmt || 0),
        asset: selectedAsset?.symbol,
        network: selectedAsset?.network,
        fiat_curr: 'INR',
        total_fees: fees,
      }),
    );
    if (isUserLogedIn && userInfo) {
      if (!userInfo.kyc_verified) {
        dispatch(savePageType(PageType.KYC_PAGE));
      } else {
        dispatch(savePageType(PageType.WALLET_ID));
      }
    } else {
      dispatch(savePageType(PageType.LOGIN_PAGE));
    }
  };

  useEffect(() => {
    dispatch(actionFiatList());
    dispatch(actionAssetList({ onChainActive: false }));
  }, []);

  useEffect(() => {
    resetAllState();

    if (selectedAsset?.symbol) {
      dispatch(
        actionAssetPriceUpdate({
          payload: {
            asset: selectedAsset?.symbol,
            network: selectedAsset?.network,
          },
          side: 'buy',
        }),
      );
    }
  }, [selectedAsset, selectedFiat]);

  useEffect(() => {
    if (
      fiatAmt &&
      selectedAsset &&
      curAssetPrice &&
      fiatAmt > selectedAsset?.min_buy
    ) {
      const cryptoNum = (fiatAmt - fees) / Number(curAssetPrice);
      setCryptoAmt(cryptoNum);
      setCostOfAsset(cryptoNum * Number(curAssetPrice));
    }
  }, [curAssetPrice, fees, fiatAmt, selectedAsset]);

  return (
    <section>
      <div className="rounded-xl bg-[var(--primary-green)] p-3">
        <p className="text-sm pb-2 pl-1 text-white">Enter amount to purchase</p>
        <div className="relative">
          <FluidInput
            inputName="test"
            onChange={(value) => enterAmountHandler(value, 'fiat')}
            value={String(fiatAmt)}
            placeHolder={'999'}
            type="number"
          />
          <button
            onClick={fiatSelectorHandler}
            className="p-1.5 m-1 mr-2 rounded-lg bg-gray-100 absolute top-0 right-0 flex items-center gap-1"
          >
            {/* <span className="text-sm font-semibold">$</span> */}
            <span className="text-sm font-semibold font-normal">
              {selectedFiat?.symbol}
            </span>
            <span>
              {' '}
              <ReactImage
                src={assetObject.dropDownArrow}
                alt="dropDownArrow icon"
                height={10}
                width={10}
              />
            </span>
          </button>
        </div>
        <p className="text-sm pb-2 pl-1 pt-4 text-white">
          Enter amount to purchase
        </p>
        <div className="relative">
          <FluidInput
            inputName="test"
            // onChange={(value) => enterAmountHandler(value, 'crypto')}
            value={String(
              roundNumber(cryptoAmt || 0, selectedAsset?.min_decimal_places) ||
                '',
            )}
            type="number"
            placeHolder={'999'}
          />
          <button
            onClick={assetSelectorHandler}
            className="p-1 m-1 mr-2 rounded-lg bg-gray-100 absolute top-0 right-0 flex items-center gap-1"
          >
            {selectedAsset?.img_url && (
              <ReactImage
                src={selectedAsset?.img_url}
                alt="dropDownArrow icon"
                height={20}
                width={20}
              />
            )}
            <span className="text-sm font-semibold">
              <p className="font-normal leading-tight">
                {selectedAsset?.symbol}
              </p>
              <p className="text-[9px] font-light leading-none">
                {selectedAsset?.network}
              </p>
            </span>
            <span>
              {' '}
              <ReactImage
                src={assetObject.dropDownArrow}
                alt="dropDownArrow icon"
                height={10}
                width={10}
              />
            </span>
          </button>
        </div>
        <p className="text-sm font-medium pt-4 text-white">
          1 {selectedAsset?.symbol} = INR {curAssetPrice}
        </p>
      </div>
      <div className="border border-gray-300 rounded-lg p-2 flex mt-4 mb-4 items-center">
        <p className="text-xs font-medium">
          Final quantity that will be received:
        </p>{' '}
        <span className="text-sm font-bold color-primary pl-1">
          {' '}
          ~{roundNumber(cryptoAmt || 0, selectedAsset?.min_decimal_places)}{' '}
          {selectedAsset?.symbol}
        </span>
      </div>
      <div className="border border-gray-300 rounded-lg p-2 mt-4 mb-4">
        <div className="pb-2.5 text-sm flex justify-between border-b">
          <p>Cost of assets</p>
          <p className="font-bold">
            ₹ {roundNumber(costOfAsset, selectedAsset?.min_decimal_places)}
          </p>
        </div>
        <div className="py-2.5 text-sm flex justify-between border-b">
          <p>Neetwork Fee</p>
          <p className="font-bold">
            ₹ {roundNumber(orderFees.network_fee * (curAssetPrice || 1), 2)}
          </p>
        </div>
        <div className="py-2.5 text-sm flex justify-between border-b">
          <p>Fluid Fee</p>
          <p className="font-bold">
            ₹ {roundNumber(orderFees.carret_fee || 0, 2) || 0}
          </p>
        </div>
        <div className="flex justify-between text-xl font-semibold pt-2 color-primary">
          <p>Total</p>
          <p>₹ {roundNumber(fiatAmt || 0)}</p>
        </div>
      </div>
      <div>
        <FluidButton
          color="primary"
          onClickHandler={ProceedHandler}
          disabled={btnDisabled}
        >
          Proceed
        </FluidButton>
        <div className="text-xs flex justify-center pt-2">
          Missing deposit?{' '}
          <p
            className="color-primary underline underline-offset-1 cursor-pointer"
            onClick={() =>
              dispatch(savePageType(PageType.MISSING_DEPOSIT_CLAIM))
            }
          >
            Claim here
          </p>
        </div>
      </div>
      {fiatSelectorStatus && (
        <MenuDrawer
          drawerState={true}
          onDrawerClose={() => setFiatSelectorStatus(false)}
        >
          <CurrencyList fiatList={fiatList} />
        </MenuDrawer>
      )}
      {assetSelectorStatus && (
        <MenuDrawer
          drawerState={true}
          onDrawerClose={() => setAssetSelectorStatus(false)}
        >
          <AssetList assetList={assetList} />
        </MenuDrawer>
      )}
    </section>
  );
};
