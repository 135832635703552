import React from 'react';
import ReactImage from '../ReactImage/ReactImage';
import { assetObject } from '../../utils/assetFileName';

interface StatusModalProps {
  statusType: string;
  text: string;
}

export const StatusModal: React.FC<StatusModalProps> = ({
  statusType,
  text,
}) => {
  return (
    <div>
      <div className="p-4 flex justify-center">
        <ReactImage
          src={
            statusType === 'success'
              ? assetObject.successSvg
              : assetObject.errorSvg
          }
          alt="status-svg"
        />
      </div>
      <p className="text-base font-semibold text-center pb-2">{text}</p>
    </div>
  );
};
