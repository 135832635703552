import React, { useState } from 'react';
import { postAddUpi, postValidateUpi } from '../../../utils/api';
import { FluidInput } from '../../../components/FluidInput/FluidInput';
import { FluidButton } from '../../../components/FluidButton/FluidButton';
import FluidModal from '../../../components/FluidModal/FluidModal';
import { StatusModal } from '../../../components/StatusModal/StatusModal';

interface IsUpiState {
  status: 'error' | 'success';
  text: string;
}
export const AddUpi = () => {
  const [isUpiAdded, setIsUpiAdded] = useState<IsUpiState>({
    status: 'success',
    text: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const addUpiIdHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const upi_id = (event.target as HTMLFormElement).upi_id.value;
    const name = (event.target as HTMLFormElement).upi_holder_name.value;

    const data = { name, upi_id: upi_id.toLowerCase() };
    try {
      const isVerified = await postValidateUpi(data);
      if (isVerified) await postAddUpi(data);
      setIsUpiAdded({ status: 'success', text: 'Your UPI ID is verified' });
      setOpenModal(true);
    } catch (error: any) {
      const respnse: any = JSON.parse(error.message);

      setIsUpiAdded({
        status: 'error',
        text: respnse?.errors ? respnse?.errors[0] : 'Your UPI ID is verified',
      });

      setOpenModal(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <p className="text-sm font-semibold pl-1 mt-4">Add UPI details</p>
      <form onSubmit={addUpiIdHandler}>
        <div className="my-4">
          <FluidInput inputName="upi_id" label="UPI Id" required />
        </div>
        <div className="my-4">
          <FluidInput
            inputName="upi_holder_name"
            label="Account holder"
            required
          />
        </div>
        <div className="mt-8">
          <FluidButton
            variant="contained"
            color="primary"
            buttonType="submit"
            loading={loading}
          >
            Add
          </FluidButton>
        </div>
      </form>
      <FluidModal
        modalStatus={openModal}
        cancelIconEnable
        onModalClose={() => setOpenModal(false)}
      >
        <StatusModal text={isUpiAdded.text} statusType={isUpiAdded.status} />
      </FluidModal>
    </div>
  );
};
