import { removeToken } from '.';

export const wipeCookies = () => {
  removeToken('fa_xrti494eeaiion');
  removeToken('fr_fasfekrkbnc933gsjsdj');
};

export const logoutHandler = () => {
  wipeCookies();
};
