import React from 'react';

interface SvgIconProps {
  svgString: string;
  className?: string;
  height?: number;
  width?: number;
}

export const SvgIcon: React.FC<SvgIconProps> = ({
  svgString,
  className,
  height = 20,
  width = 20,
}) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: svgString }}
      className={className}
      style={{ height: `${height}px`, width: `${width}px` }}
    />
  );
};
