import React, { FormEvent, MouseEvent, useEffect, useState } from 'react';
import { SvgIcon } from '../../components/ReactImage/SvgIcon';
import { assetObject } from '../../utils/assetFileName';
import FluidModal from '../../components/FluidModal/FluidModal';
import { FluidButton } from '../../components/FluidButton/FluidButton';
import { FluidInput } from '../../components/FluidInput/FluidInput';
import { FluidHeader } from '../../components/FluidHeader/FluidHeader';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { savePageType } from '../../redux/reducer/CommonReducer';
import { PageType } from '../../utils/constants';
import { MenuDrawer } from '../../components/MenuDrawer/MenuDrawer';
import AssetList from '../../components/AssetList/AssetList';
import { actionAssetList } from '../../redux/action/CommonAction';
import ReactImage from '../../components/ReactImage/ReactImage';
import { addWalletAddressApi } from '../../utils/api';
import { StatusModal } from '../../components/StatusModal/StatusModal';
import { errorReaderToast } from '../../utils';

export const AddAddressLayout = () => {
  const dispatch = useAppDispatch();
  const [assetSelectorStatus, setAssetSelectorStatus] = useState(false);
  const { assetList, selectedAsset } = useAppSelector((state) => state.common);
  const [statusModal, setStatusModal] = useState(false);

  const assetSelectorHandler = () => {
    setAssetSelectorStatus(true);
  };

  const onSuccessModalClose = () => {
    dispatch(savePageType(PageType.ADDRESS_LIST));
  };

  const addWalletAddressHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const address = (event.target as HTMLFormElement).address.value;
    const platform = (event.target as HTMLFormElement).platform.value;
    const address_label = (event.target as HTMLFormElement).address_label.value;
    if (selectedAsset?.id)
      try {
        const data = {
          asset: selectedAsset?.id,
          network: selectedAsset?.network,
          address,
          platform,
          address_label,
        };
        const res = await addWalletAddressApi(data);
        setStatusModal(true);
      } catch (error) {
        console.log(error);
        errorReaderToast(error);
      }
  };

  useEffect(() => {
    dispatch(actionAssetList({ onChainActive: true }));
  }, []);

  return (
    <>
      <FluidHeader
        title="Add Address"
        onClickHandler={() => dispatch(savePageType(PageType.ADDRESS_LIST))}
      />
      <form className="flex flex-col" onSubmit={addWalletAddressHandler}>
        <div className="p-2">
          <div className="pb-2 relative">
            <FluidInput inputName="address" label="Address" required />
            <button
              onClick={assetSelectorHandler}
              className="p-1 m-1 mr-2 rounded-lg bg-gray-100 absolute bottom-0 right-0 flex items-center gap-1 mb-3"
              type="button"
            >
              {selectedAsset?.img_url && (
                <ReactImage
                  src={selectedAsset?.img_url}
                  alt="dropDownArrow icon"
                  height={20}
                  width={20}
                />
              )}
              <span className="text-sm font-semibold">
                <p className="font-normal leading-tight">
                  {selectedAsset?.symbol}
                </p>
                <p className="text-[9px] font-light leading-none">
                  {selectedAsset?.network}
                </p>
              </span>
              <span>
                {' '}
                <ReactImage
                  src={assetObject.dropDownArrow}
                  alt="dropDownArrow icon"
                  height={10}
                  width={10}
                />
              </span>
            </button>
          </div>
          <div className="pb-2">
            <FluidInput inputName="platform" label="Platform" required />
          </div>
          <div className="pb-2">
            <FluidInput
              inputName="address_label"
              label="Address Label"
              required
            />
          </div>
        </div>
        <div className="flex gap-2 p-2">
          <FluidButton
            variant="outlined"
            onClickHandler={() => dispatch(savePageType(PageType.ADDRESS_LIST))}
          >
            Cancel
          </FluidButton>
          <FluidButton variant="contained" buttonType="submit">
            Submit
          </FluidButton>
        </div>
      </form>
      {assetSelectorStatus && (
        <MenuDrawer
          drawerState={true}
          onDrawerClose={() => setAssetSelectorStatus(false)}
        >
          <AssetList assetList={assetList} showFees={false} />
        </MenuDrawer>
      )}
      <FluidModal modalStatus={statusModal} onModalClose={onSuccessModalClose}>
        <StatusModal
          statusType="success"
          text="Address Added successfully, In review."
        />
      </FluidModal>
    </>
  );
};
