import React, { ChangeEvent, useState } from 'react';
import styles from './fluidInput.module.scss';

interface FluidInputProps {
  label?: string;
  placeHolder?: string;
  inputName: string;
  onChange?: (value: string) => void;
  type?: string;
  required?: boolean;
  inputError?: boolean;
  helperText?: string;
  value?: string;
}

export const FluidInput: React.FC<FluidInputProps> = ({
  label,
  placeHolder,
  inputName,
  onChange,
  type = 'text',
  required = false,
  inputError = false,
  helperText = '',
  value,
}) => {
  const [defaultValue, setDefaultValue] = useState('');
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDefaultValue(event.target.value);
    if (onChange) onChange(event.target.value);
  };

  return (
    <div className={styles.fluidInput}>
      {label && <label htmlFor={inputName}>{label}</label>}
      <input
        placeholder={placeHolder}
        name={inputName}
        onChange={handleChange}
        type={type}
        required={required}
        className={`border ${
          inputError ? 'border-[var(--error-red)]' : 'border-gray-200'
        }`}
        value={value || defaultValue}
      ></input>
      {inputError && (
        <p className="text-[10px] pl-1 text-[var(--error-red)]">{helperText}</p>
      )}
    </div>
  );
};
