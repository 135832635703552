import React from 'react';
import { OrderHistoryInterface } from '../../../typing/interface';
import { roundNumber } from '../../utils';
import moment from 'moment';
interface orderDetailsProps {
  selectedOrder: OrderHistoryInterface | null;
}

export const OrderDetails: React.FC<orderDetailsProps> = ({
  selectedOrder,
}) => {
  return (
    <div>
      <div className="px-4 py-2 grid grid-cols-2 gap-2">
        <div>
          <p className="font-semibold">Asset Pair</p>
          <p>USDT / INR</p>
        </div>
        <div>
          <p className="font-semibold">Order Value</p>
          <p>{roundNumber(Number(selectedOrder?.final_cost_to_user))}</p>
        </div>
        <div>
          <p className="font-semibold">Added on</p>
          <p>
            {moment(selectedOrder?.executed_time).format('DD MMMM YYYY, HH:mm')}
          </p>
        </div>
        <div>
          <p className="font-semibold">Network Fee</p>
          <p>{selectedOrder?.network_fee}</p>
        </div>
        <div>
          <p className="font-semibold">Fluid Fee</p>
          <p>{selectedOrder?.fee}</p>
        </div>

        <div>
          <p className="font-semibold">Avg Price</p>
          <div className="flex justify-between">
            <p className="truncate w-70%">{selectedOrder?.avg_price}</p>
            {/* <button>
          <ReactImage
            src={assetObject.copySvg}
            alt="copy img"
            height={20}
            width={20}
            className="w-30%"
          />
        </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
